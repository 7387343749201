import { theme } from '@faxi/web-component-library';
import { fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFileInput = styled.div`
  height: 38px;

  & label,
  & .esg-file-input__file {
    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
  }

  label {
    gap: ${theme.sizes.SIZE_12};
    padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
    border-radius: ${theme.sizes.SIZE_8};
    cursor: pointer;
    border: 1px solid var(--PRIMARY_1_1);
    font-size: ${theme.fontSizes.FONT_14};
    text-align: center;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > input {
    display: none;
  }

  &.esg-file-input--disabled label {
    cursor: no-drop;
    grid-template-columns: 1fr 6fr;
  }

  .esg-file-input {
    &__file {
      gap: ${theme.sizes.SIZE_8};

      padding: 6px 6px 6px 16px;
      border: 1px solid var(--GRAY-C1);

      > .wcl-icon {
        ${fontSize(theme.fontSizes.FONT_16)};
      }

      span {
        padding: ${theme.sizes.SIZE_2} 0;
        text-align: center;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      button {
        padding: ${theme.sizes.SIZE_4} !important;
        border-radius: 50%;

        .wcl-icon {
          ${size(theme.sizes.SIZE_16)};
        }
      }
    }
  }
`;

export { StyledFileInput };

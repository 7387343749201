import { theme } from '@faxi/web-component-library';
import { fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledTextPreview = styled.div`
  ${fontSize(theme.fontSizes.FONT_14)}
  word-break: break-word;
  width: 100%;

  &.esg-text-preview {
    &--heading {
      ${fontSize(theme.fontSizes.FONT_30)}
      font-weight: 700;
    }
    &--title {
      ${fontSize(theme.fontSizes.FONT_22)}
      font-weight: 600;
    }
    &--subtitle {
      ${fontSize(theme.fontSizes.FONT_18)}
      font-weight: 500;
    }
    &--body-text {
      ${fontSize(theme.fontSizes.FONT_16)}
    }
  }
`;

export { StyledTextPreview };

import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledUserMentionOption = styled.div`
  ${flex('row', 'space-between', 'center')};

  gap: ${theme.sizes.SIZE_32};
  padding: ${theme.sizes.SIZE_2} ${theme.sizes.SIZE_6};

  .esg-basic-tag {
    ${fontSize('8px')};

    padding: ${theme.sizes.SIZE_4} ${theme.sizes.SIZE_6};
    border-radius: ${theme.sizes.SIZE_4};
  }

  &:first-of-type {
    padding-top: ${theme.sizes.SIZE_6};
  }

  &:last-of-type {
    padding-bottom: ${theme.sizes.SIZE_6};
  }

  .esg-user-mention-option {
    &__name-container {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_4};

      img {
        ${size(theme.sizes.SIZE_20)};
        border-radius: 50%;
      }
    }
  }
`;

export { StyledUserMentionOption };

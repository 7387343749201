import { Image } from '@faxi/web-component-library';
import classNames from 'classnames';
import { Role } from 'models';
import { FC, PropsWithChildren } from 'react';

import { BasicTag } from '../../../../../components';
import { USER_ROLE_NAME_MAPPER_SHORT } from '../../../Users/pages/UserRoles/constants';
import { StyledUserMentionOption } from './UserMentionOption.styled';

export type UserMentionOptionProps = PropsWithChildren<{
  contributor?: string;
  name: string;
  role?: Role;
}> &
  Pick<React.ComponentProps<'div'>, 'onClick' | 'onKeyDown' | 'className'>;

//TODO change avatar implementation when we add avatars
const UserMentionOption: FC<UserMentionOptionProps> = (props) => {
  const { name, contributor, role, className, ...rest } = props;
  return (
    <StyledUserMentionOption
      className={classNames('esg-user-mention-option', className)}
      {...rest}
    >
      <div className="esg-user-mention-option__name-container">
        <Image
          src={contributor ?? '/assets/images/avatar.png'}
          alt={`${name}-avatar`}
        />
        <span>{name}</span>
      </div>
      {role && (
        <BasicTag
          text={USER_ROLE_NAME_MAPPER_SHORT[role.name]}
          color={role.color}
        />
      )}
    </StyledUserMentionOption>
  );
};

export default UserMentionOption;

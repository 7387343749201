import api from 'api';
import { CampaignItem, TreeNodeElement } from 'models';
import { FC, ReactNode, useContext, useMemo, useRef } from 'react';
import {
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../api/routes';
import { campaignPathCheckpoints } from '../../utils';
import CampaignProviderContext, {
  CampaignProviderContextProps,
} from './Campaign.context';

type CampaignProviderProps = {
  children?: ReactNode;
};

export const mapBaseCampaignToTreeNodeElement = (
  { id, name, createdAt, updatedAt, children, type }: CampaignItem,
  navigate: NavigateFunction,
  location: Location,
  depth = 0,
  path = ''
): TreeNodeElement => ({
  id,
  name,
  createdAt,
  updatedAt,
  children:
    type === 'data_collection_element'
      ? undefined
      : children?.map((c) =>
          mapBaseCampaignToTreeNodeElement(
            c,
            navigate,
            location,
            depth + 1,
            `${path}/${campaignPathCheckpoints[depth]}/${id}`
          )
        ),
  iconName:
    type === 'data_collection_element' ? 'magnifying-glass-chart' : undefined,
  color: type === 'data_collection_element' ? '#0066D5' : undefined,
  to: `${path}/${campaignPathCheckpoints[depth]}/${id}${type === 'data_collection_element' ? '?form=yes' : ''}`,
});

const CampaignProvider: FC<CampaignProviderProps> = (props) => {
  const { children } = props;

  const { campaign = '' } = useParams<{
    campaign: string;
  }>();

  const deletedCampaign = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: { data: campaignRoot } = { data: {} },
    error,
    mutate: mutateTree,
    isLoading,
  } = useSWR(
    !deletedCampaign.current && campaign
      ? API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_TREE(campaign)
      : null
  );

  // MUTATION WILL BE TRIGGERED IN A COMPONENT WHICH EXTENDS THIS ONE
  const { trigger: campaignItemsMutationRequest } = api.useMutation<void>(
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.BASE(),
    {
      revalidate: false,
      // THIS HAS AN EDGE CASE AND THAT IS WHEN A CAMPAIGN IS DELETED (ROOT), THEN MUTATE TREE SHOULD NOT BE CALLED
      // BUT IT IS, AND IT IS AVOIDED WITH deletedCampaign REF
      onSuccess: () => {
        mutateTree();
      },
    }
  );

  const tree = useMemo(
    () =>
      campaignRoot
        ? mapBaseCampaignToTreeNodeElement(
            campaignRoot as CampaignItem,
            navigate,
            location
          )
        : undefined,
    [campaignRoot, location, navigate]
  );

  return (
    <CampaignProviderContext.Provider
      value={{
        deletedCampaign,
        tree,
        isError: error,
        mutateTree,
        campaignItemsMutationRequest,
        isLoading,
      }}
    >
      {children}
    </CampaignProviderContext.Provider>
  );
};

const useCampaignProvider = (): CampaignProviderContextProps =>
  useContext(CampaignProviderContext);

export { CampaignProvider, useCampaignProvider };

import styled from 'styled-components';

import OrganisationsLayout from '../../../../../layouts/OrganisationsLayout';

const StyledOrganisationsWrapper = styled(OrganisationsLayout)`
  .esg-tree-node-component__sub-folders {
    width: 100%;
    overflow-x: auto;
  }
`;

export { StyledOrganisationsWrapper };

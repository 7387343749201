import { FileUpload, FileUploadProps } from '@faxi/web-component-library';
import { FieldProps } from '@faxi/web-form';
import { FC } from 'react';

import { StyledFileUploadField } from './FileUploadField.styled';

export type FileUploadFieldProps = FieldProps<File[], (files: File[]) => void> &
  FileUploadProps;

const FileUploadField: FC<FileUploadFieldProps> = (
  props: FileUploadFieldProps
) => {
  return (
    <StyledFileUploadField>
      <FileUpload {...props} />
    </StyledFileUploadField>
  );
};

export default FileUploadField;

import { CampaignItem, IDataModule } from 'models';
import { createContext } from 'react';

export type CampaignItemContextProps = {
  mutating?: boolean;
  campaignItem?: CampaignItem;
  removeActiveCampaignItem: () => void;
  setCampaignItem: (data?: CampaignItem) => void;
  editCampaignItem: (data: Partial<CampaignItem>) => Promise<void>;
  duplicateCampaignItem: (data: IDataModule) => Promise<void>;
};

export default createContext<CampaignItemContextProps>({
  setCampaignItem: () => {},
  duplicateCampaignItem: () => Promise.resolve(),
  editCampaignItem: () => Promise.resolve(),
  removeActiveCampaignItem: () => {},
});

import {
  Button,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { ChangePasswordFrom, User, UserForm } from 'models';
import ManageUserModal from 'pages/Users/components/ManageUserModal';
import { FC, PropsWithChildren } from 'react';

import ChangePasswordModal from '../ChangePasswordModal';
import {
  StyledDividerProfileActions,
  StyledUserProfileActions,
} from './UserProfileActions.styled';

export type UserProfileActionsProps = PropsWithChildren<{
  onDelete?: () => Promise<void>;
  onEdit?: (data: UserForm) => Promise<void>;
  onChangePassword?: (data: ChangePasswordFrom) => Promise<void>;
  user?: User;
  isLoading?: boolean;
  useDivider?: boolean;
  isEditingOwnProfile?: boolean;
}>;

const UserProfileActions: FC<UserProfileActionsProps> = (props) => {
  const {
    onDelete,
    onEdit,
    onChangePassword,
    user,
    isLoading,
    useDivider = true,
    isEditingOwnProfile = false,
  } = props;

  const { open, openModal, closeModal } = useModalUtilities();

  const {
    open: openChangePassword,
    openModal: openChangePasswordModal,
    closeModal: closeChangePassword,
  } = useModalUtilities();

  const { prompts } = useUtilities();

  if (!user) return null;

  const { firstName, lastName, jobTitle, roles } = user;

  return (
    <>
      {useDivider && <StyledDividerProfileActions />}
      <StyledUserProfileActions className="esg-user-profile-actions">
        {onEdit && (
          <>
            <Button
              variant="outline"
              onClick={openModal}
              icon={<Icon name="pen" />}
            >
              Edit user details
            </Button>
            {open && (
              <ManageUserModal
                initialData={{
                  firstName,
                  lastName,
                  jobTitle,
                  roleId: roles[0].id,
                }}
                onClose={() => {
                  closeModal();
                }}
                loading={isLoading}
                onSubmit={async (data) => {
                  await onEdit(data);
                  closeModal();
                }}
                isEditingOwnProfile={isEditingOwnProfile}
              />
            )}
          </>
        )}
        {onDelete && (
          <Button
            variant="delete-outline"
            icon={<Icon name="trash-can" className="color-secondary" />}
            disabled={isEditingOwnProfile}
            onClick={async (e) => {
              await prompts.delete({
                type: 'delete',
                confirmButtonText: 'Delete',
                cancelButtonText: 'Do not delete',
                title: `Delete ${user.firstName} ${user.lastName}`,
                content: `Are you sure you want to delete ${user.firstName} ${user.lastName} from users?`,
                buttonIcon: 'trash-can',
                iconPosition: 'left',
                confirmButtonVariant: 'delete-ghost',
                titleIcon: <Icon name="triangle-exclamation" />,
                triggerRef: e.target as HTMLButtonElement,
                onConfirm: async () => {
                  await onDelete();
                },
              });
            }}
          >
            Delete user
          </Button>
        )}
        {onChangePassword && (
          <>
            <Button
              variant="outline"
              onClick={openChangePasswordModal}
              icon={<Icon name="lock" />}
            >
              Change password
            </Button>
            {openChangePassword && (
              <ChangePasswordModal
                onClose={() => {
                  closeChangePassword();
                }}
                loading={isLoading}
                onSubmit={async (data) => await onChangePassword(data)}
              />
            )}
          </>
        )}
      </StyledUserProfileActions>
    </>
  );
};

export default UserProfileActions;

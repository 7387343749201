import { theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionDashboardCompany = styled.div`
  padding: ${theme.sizes.SIZE_32};
  ${size('100%')};
  min-width: ${theme.sizes.SIZE_800};
  overflow: auto;

  .esg-tree-node-component__icon {
    padding-right: ${theme.sizes.SIZE_16};

    &:hover {
      background-color: var(--GRAY-EF);

      .esg-contributors-list__wrapper__extra {
        background-color: var(--WHITE-FF);
      }
    }

    .esg-chevron-icon,
    .esg-node-icon {
      cursor: pointer;
    }
  }

  .esg-tree-node-component__base-node,
  .esg-tree-node-component__icon,
  .esg-tree-node-component__sub-folders {
    width: 100%;
    ${fontSize(theme.fontSizes.FONT_18)};
  }

  .esg-approval-progress-bar,
  .esg-approval-chip {
    height: 32px;
  }

  .esg-tree-node-component__icon {
    margin-bottom: 8px;
  }

  .esg-tree-node-component__sub-folders {
    margin-bottom: 8px;
  }

  .esg-tree-node-component:not(:last-child) {
    > .esg-tree-node-component__sub-folders {
      border-bottom: 1px solid var(--GRAY-C1);
    }
  }

  .esg-tree-node-component__sub-folders {
    .esg-tree-node-component__icon--leaf {
      margin-bottom: ${theme.sizes.SIZE_8};
      padding-left: ${theme.sizes.SIZE_32};
    }
  }

  .esg-session-dashboard-company {
    &__is-preparing-node {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_16};
    }

    &__breadcrumbs {
      ${fontSize(theme.fontSizes.FONT_14)}
    }

    &__header {
      ${flex('row', 'space-between', 'center')};
      padding: ${theme.sizes.SIZE_32} 0 ${theme.sizes.SIZE_24};
      border-bottom: 1px solid var(--GRAY-C1);

      &__text {
        ${flex('row', 'flex-start', 'center')};
        gap: ${theme.sizes.SIZE_20};

        img {
          ${size('42px')};
        }
      }

      &__progress {
        ${flex('column', 'center', 'center')};

        flex: 0 0 270px;
        gap: ${theme.sizes.SIZE_16};
      }

      h1 {
        font-weight: 400;
      }
    }

    &__subheaders {
      ${flex('row', 'space-between', 'center')};
      gap: ${theme.sizes.SIZE_64};

      margin: ${theme.sizes.SIZE_32} 0;
      padding: 0 ${theme.sizes.SIZE_16} 0 ${theme.sizes.SIZE_72};

      :nth-of-type(1) {
        flex-grow: 1;
      }

      :nth-of-type(2) {
        flex: 0 0 204px;
      }

      :nth-of-type(3) {
        flex: 0 0 176px;
      }
    }
  }
`;

export { StyledSessionDashboardCompany };

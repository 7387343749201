import { Progress } from '../../../../models';
import CompanyTreeNode, {
  OrganisationSessionCampaignItem,
} from './components/CompanyTreeNode/CompanyTreeNode.component';

export const ORGANISATION_SESSION_MOCK_CONTRIBUTORS_PROGRESS: {
  contributors: string[];
  progress: Progress;
}[] = [
  {
    contributors: [
      'dule.jpeg',
      'kumca.png',
      'cvele.png',
      'joca.png',
      'ivca.png',
      'apox.jpeg',
      'mateja.jpeg',
      'nemca.jpeg',
    ],
    progress: {
      progress: 85,
      total: 100,
      completed: 85,
    },
  },
  {
    contributors: ['cvele.png', 'nemca.jpeg', 'ivca.png', 'joca.png'],
    progress: {
      progress: 2,
      total: 8,
      completed: 6,
    },
  },
  {
    contributors: ['dule.jpeg', 'apox.jpeg'],
    progress: {
      progress: 3,
      total: 5,
      completed: 85,
    },
  },
  {
    contributors: ['joca.png', 'cvele.png', 'mateja.jpeg'],
    progress: {
      progress: 4,
      total: 4,
      completed: 85,
    },
  },
  {
    contributors: ['dule.jpeg', 'kumca.png', 'ivca.png'],
    progress: {
      progress: 4,
      total: 4,
      completed: 85,
    },
  },
  {
    contributors: ['kumca.png', 'joca.png', 'cvele.png', 'apox.jpeg'],
    progress: {
      progress: 3,
      total: 5,
      completed: 85,
    },
  },
  {
    contributors: ['nemca.jpeg', 'mateja.jpeg'],
    progress: {
      progress: 4,
      total: 4,
      completed: 85,
    },
  },
  {
    contributors: ['dule.jpeg', 'kumca.png', 'joca.png', 'ivca.png'],
    progress: {
      progress: 4,
      total: 4,
      completed: 85,
    },
  },
  {
    contributors: [
      'joca.png',
      'cvele.png',
      'nemca.jpeg',
      'dule.jpeg',
      'apox.jpeg',
    ],
    progress: {
      progress: 1,
      total: 6,
      completed: 85,
    },
  },
  {
    contributors: ['kumca.png', 'mateja.jpeg'],
    progress: {
      progress: 2,
      total: 7,
      completed: 6,
    },
  },
  {
    contributors: ['joca.png', 'cvele.png', 'ivca.png'],
    progress: {
      progress: 3,
      total: 3,
      completed: 3,
    },
  },
  {
    contributors: ['nemca.jpeg', 'dule.jpeg', 'apox.jpeg'],
    progress: {
      progress: 4,
      total: 5,
      completed: 85,
    },
  },
  {
    contributors: ['apox.jpeg', 'joca.png', 'kumca.png', 'ivca.png'],
    progress: {
      progress: 1,
      total: 3,
      completed: 85,
    },
  },
  {
    contributors: ['cvele.png', 'nemca.jpeg'],
    progress: {
      progress: 2,
      total: 2,
      completed: 6,
    },
  },
];

export const extendOrganisationSessionCampaignItemNodes = (
  node: OrganisationSessionCampaignItem
): OrganisationSessionCampaignItem => {
  node.element = <CompanyTreeNode {...node} />;
  node.children = node.children?.map(
    extendOrganisationSessionCampaignItemNodes
  );

  return node;
};

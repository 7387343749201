import { ProgressBar } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FormStatus, Progress } from 'models';
import { FC, PropsWithChildren } from 'react';

import { StyledApprovalProgressBar } from './ApprovalProgressBar.styled';
import { ApprovalChip } from './components';

export type ApprovalProgressBarProps = PropsWithChildren<{
  status: `${FormStatus}`;
  title?: string;
  progress?: Progress;
  className?: string;
}>;

const ApprovalProgressBar: FC<ApprovalProgressBarProps> = ({
  status,
  title,
  className,
  progress,
  ...rest
}) => (
  <StyledApprovalProgressBar
    className={classNames('esg-approval-progress-bar', className)}
  >
    {title && <p className="esg-approval-progress-bar__title">{title}</p>}

    {FormStatus.InProgress === status ? (
      <ProgressBar
        color="var(--BLUE-C3)"
        variant="rich"
        currentStep={progress?.progress || 0}
        numberOfSteps={100}
        {...rest}
      />
    ) : (
      <ApprovalChip status={status} />
    )}
  </StyledApprovalProgressBar>
);

export default ApprovalProgressBar;

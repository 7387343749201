import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionDetails = styled.div`
  ${flex('row')};
  overflow: auto;

  .esg-session-details {
    &__content {
      ${size('100%')};
      overflow: auto;
    }
  }
`;

export { StyledSessionDetails };

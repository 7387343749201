import { Form, FormField } from '@faxi/web-form';
import classNames from 'classnames';
import { FC, PropsWithChildren, useMemo } from 'react';

import { CheckboxListField } from '../../../../../../../components';
import Icon from '../../../../../../../components/Icon';
import { UserRoleForm } from '../../../../../../../models/User';
import {
  StyledPermissionHeaderRow,
  StyledPermissionRow,
} from './UserRolePermissions.styled';

export type UserRolePermissionsProps = PropsWithChildren<{
  onSubmit: () => Promise<void>;
  permissions: UserRoleForm;
  initialData?: Record<string, any>;
}>;

const UserRolePermissions: FC<UserRolePermissionsProps> = (props) => {
  const { onSubmit, permissions, initialData } = props;

  const permissionsArray = useMemo(
    () =>
      Object.entries(permissions).map(([key, value]) => ({
        section: key,
        ...value,
      })),
    [permissions]
  );

  const onChangeOptionCallback = (
    value: string[],
    excludes?: string[],
    includes?: string[]
  ): string[] => [
    ...new Set(value).difference(new Set(excludes)).union(new Set(includes)),
  ];

  return (
    <Form onSubmit={onSubmit} initialData={initialData} refreshInitialData>
      <StyledPermissionHeaderRow className="esg-user-role-permissions__header">
        <div>Section</div>
        <div>
          <div>Action</div>
          <div>Permission</div>
        </div>
      </StyledPermissionHeaderRow>

      {permissionsArray.map(({ section, actions, icon }) => (
        <StyledPermissionRow
          className="esg-user-role-permissions"
          key={section}
        >
          <div>
            {<Icon name={icon} />}
            {`${section.charAt(0).toUpperCase()}${section.slice(1)}`}
          </div>
          <div>
            {actions ? (
              <FormField
                name={section}
                className={classNames(
                  'esg-user-role-permissions__checkbox',
                  'underlined'
                )}
                component={CheckboxListField}
                labelPosition="left"
                options={Object.values(actions).map(
                  ({ label, name, excludes, includes }) => ({
                    value: name,
                    className: classNames({
                      'indented': includes?.length,
                      'underlined': !(includes?.length || excludes?.length),
                    }),
                    label,
                    onChangeOptionCallback: (value: string[]) =>
                      onChangeOptionCallback(value, excludes, includes),
                  })
                )}
                disabled
              />
            ) : (
              'No actions available'
            )}
          </div>
        </StyledPermissionRow>
      ))}
    </Form>
  );
};

export default UserRolePermissions;

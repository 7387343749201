import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionActivity = styled.div`
  ${flex('column', 'center', 'initial')};
  padding: ${theme.sizes.SIZE_24};

  .esg-session-activity {
    &__header {
      ${flex('row', 'flex-start', 'center')};
      gap: ${theme.sizes.SIZE_10};
      padding: ${theme.sizes.SIZE_24};
      ${fontSize(theme.fontSizes.FONT_14, theme.fontSizes.FONT_14)};

      .wcl-icon {
        ${fontSize(theme.fontSizes.FONT_30)};
      }
    }

    &__content {
      padding: ${theme.sizes.SIZE_24};

      .esg-data-grid-preview {
        margin-top: ${theme.sizes.SIZE_24};
        overflow: auto;

        &__container {
          grid-template-columns:
            minmax(${theme.sizes.SIZE_288}, ${theme.sizes.SIZE_320}) minmax(
              ${theme.sizes.SIZE_184},
              ${theme.sizes.SIZE_200}
            )
            minmax(${theme.sizes.SIZE_224}, ${theme.sizes.SIZE_248})
            minmax(${theme.sizes.SIZE_192}, ${theme.sizes.SIZE_200})
            minmax(${theme.sizes.SIZE_192}, ${theme.sizes.SIZE_216});
          gap: ${theme.sizes.SIZE_20};

          & > div:last-child {
            ${flex('row', 'flex-end', 'center')}
            padding-right:${theme.sizes.SIZE_32};
          }
        }
      }
    }
  }
`;

export { StyledSessionActivity };

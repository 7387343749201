import env from '../../env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/organisations/${apiVersion}`;

export const ORGANISATIONS = {
  ORGANISATION: (id: string) => `${baseUrl}/${id}`,
  ORGANISATION_TREE: (id: string) => `${baseUrl}/${id}/tree`,
};

import { FC, PropsWithChildren, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../../../api/routes';
import { Loading } from '../../../../../../../components';
import { ApiData, Permissions, User } from '../../../../../../../models';
import { parsePermissionsArray } from '../../../../../../../utils/getUserInformationFromToken';
import UserRoleHeader from '../../../../pages/UserRole/components/UserRoleHeader';
import UserRolePermissions from '../../../../pages/UserRole/components/UserRolePermissions';
import { USER_ROLE_FORM_DATA } from '../../../../pages/UserRole/data';

export type UserByIdNotificationsProps = PropsWithChildren<{}>;

const UserByIdNotifications: FC<UserByIdNotificationsProps> = (props) => {
  //TODO: change with get user by id request when BE is ready
  const { data: { data: users } = {}, isLoading } = useSWR<
    ApiData<User[]>,
    Error
  >(API_ROUTES.USERS.USER);

  const { userId } = useParams();
  const userRole = users?.find((user) => user.id === userId)?.roles[0]!;

  const { data: { data } = {}, isLoading: isPermissionsLoading } =
    useSWR<Permissions>(API_ROUTES.USERS.PERMISSIONS(userRole.id));

  const initialData = useMemo(() => {
    if (!data?.length) return [];

    const permissionsArray = data.map(({ name }) => name);

    return parsePermissionsArray(permissionsArray);
  }, [data]);

  if (isLoading || isPermissionsLoading) {
    return <Loading />;
  }

  return (
    <>
      <UserRoleHeader
        description={userRole.description}
        name={userRole.name}
        color={userRole.color}
      />
      <UserRolePermissions
        onSubmit={() => Promise.resolve()}
        permissions={USER_ROLE_FORM_DATA}
        initialData={initialData}
      />
    </>
  );
};

export default UserByIdNotifications;

import { elevate_l, elevate_xxl, theme } from '@faxi/web-component-library';
import { flex, fontSize, phablet, size } from '@faxi/web-css-utilities';
import { createGlobalStyle, css } from 'styled-components';
import {
  breadcrumbsStyles,
  buttonStyles,
  menuStyles,
  modalStyles,
  progressBarStyles,
  selectStyles,
  snackbarsStyles,
  textareaStyles,
} from 'styles';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: ToyotaType !important;
  }

  .esg-canvas-module {
    &--dropdown {
      > div {
      min-width: ${theme.sizes.SIZE_200};
    }

    &__ul {
      ${flex('column')};

      gap: ${theme.sizes.SIZE_16};
      list-style: none;
      padding-left: ${theme.sizes.SIZE_16};

      &__radio {
          border-radius: 50%;
        }

      li {
        ${flex('row', 'flex-start', 'center')};

        list-style:none;
        gap: ${theme.sizes.SIZE_8};
        
        > p {
          ${fontSize(theme.sizes.SIZE_12, theme.sizes.SIZE_12)}
          line-height: ${theme.sizes.SIZE_16};
        }

        >span {
          ${size(theme.sizes.SIZE_16)};
         
          background-color: var(--GRAY-EF);
          border: ${theme.sizes.SIZE_1} solid var(--GRAY-54);
        }
      }
    }
  }
  }

  html, body {  
    margin: 0;
    text-size-adjust: none;
    height: 100%;

    p {
      margin: 0;
    } 
  }

  body {
    --BLACK-00: #000000;

    --BLUE-08: #0894B1;
    --BLUE-00: #0066D5;
    --BLUE-06: #06C9DA;
    --BLUE-C3: #C3DFFF;
    --BLUE-29: #298DFF;

    --TEAL-00: #00708d;

    --BROWN-A3: #A35501;

    --ORANGE-EE: #EE7D01;

    --GRAY-40: #404040;
    --GRAY-C1: #c1c3c6;
    --GRAY-98: #989CA0;
    --GRAY-EF: #EFF0F0;
    --GRAY-54: #545a61;
    --GRAY-65: #656A71;
    --GRAY-70: #707070;
    --GRAY-8F-B3: #8F8F8FB3;
    --GRAY-D9: #D9D9D9;
    --GRAY-C9: #C9C9C9;

    --ORANGE-D0: #D06D01;
    --ORANGE-E6: #E68E1B;

    --RED-EB: #eb0a1e;
    --RED-C4: #c40818;
    --RED-D5: #D50303;
    --RED-FC: #FCECEC;
    --RED-8F: #8F0000;
    
    --GREEN-E3: #E3F4EA;
    --GREEN-12: #127A3C;
    --GREEN-97: #97B100;

    --KHAKI-FF: #FFF1BA;
    --KHAKI-81: #816700;

    --PURPLE-B6: #B66ECA;

    --WHITE-FF: #FFFFFF;

    --PRIMARY_1_1: var(--BLACK-00);
    --PRIMARY_1_2: var(--BLACK-00);
    --PRIMARY_2_1: var(--GRAY-D9);

    --SECONDARY_1_1: var(--RED-EB);
    --SECONDARY_1_2: var(--BLACK-00);

    --SUCCESS_1_1: var(--GREEN-12);

    --ALERT_ERROR_1_1: var(--RED-D5);
    
    --ACCENT_1_1: var(--BLACK-00);
    --ACCENT_1_6: var(--GRAY-D9);

    --SUCCESS_1_1: var(--GRAY-D9);
    --SUCCESS_2_1: var(--GRAY-98);
    --MODAL-OVERLAY-SHADOW: var(--GRAY-8F-B3);
  }

  main {
    width: 100%;
    overflow: auto;

    ${phablet(css`
      flex-direction: column;
    `)}
  }

  #root {
    ${size('100%')};
    padding: 0;
    display: flex;
  }

  html body .wcl-sidebar {
    flex-shrink: 0;

    .wcl-sidebar__container {
      padding-top: ${theme.sizes.SIZE_20};

      .wcl-sidebar__collapse-btn {
        top: 136px;
      }
    }

    .wcl-sidebar-main-element {

      &:not(.wcl-sidebar-main-element--selected):hover {
        background-color: var(--RED-8F);
        border-radius: ${theme.sizes.SIZE_8};
      }

      &__title {
        text-align: start;
        padding-left: ${theme.sizes.SIZE_4};
      }
    }

    .wcl-sidebar-main__list {
      padding-top: ${theme.sizes.SIZE_56};
    }
  }

  hr {
    display: block;
    height: 1px;
    width: 100%;
    border: 0;
    border-top: 1px solid var(--GRAY-C1);
  }

  .hover-effect {
    ${elevate_l};
    transition: transform 150ms linear !important; 

    > * {
      transition: transform 150ms linear !important; 
    }

    &:hover {
      ${elevate_xxl};
      transform: scale3d(1.005, 1.005, 1.005) !important;

      > * {
        transform: scale3d(1.005, 1.005, 1.005) !important;
      }
    }
  }

  .color-secondary {
    color: var(--SECONDARY_1_1);
  }

  .width-100 {
    width: 100%;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--PRIMARY_1_1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--SECONDARY_1_1);
  }

  .glow-scroll {
    justify-content: flex-start;
  }

  .esg-ghost-button-padded {
    padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_24} !important;
  }

  .esg-integration-field .wcl-dropdown__content .wcl-select__option__icon {
    ${size(theme.sizes.SIZE_24)};
    aspect-ratio: 1;
  }

  .overlay {
    z-index: 4;
  }

  .user-image {
    position: relative;

    &-container {
      border-radius: 50%;
      background: linear-gradient(var(--GRAY-EF), var(--GRAY-D9));
      color: var(--GRAY-8F-B3);
      position: relative;
      overflow: hidden;

      .wcl-icon {
        position: absolute;
        bottom: 0;
        left: 15%;
      }
    }
  }
 
 .tippy-content {
    a {
      color: var(--WHITE-FF);
    }
  }

  //predefined wcl component styles
  ${modalStyles}
  ${progressBarStyles}
  ${breadcrumbsStyles}
  ${menuStyles}
  ${buttonStyles}
  ${selectStyles}
  ${snackbarsStyles}
  ${textareaStyles}
`;

import { Button } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { CommentType } from 'models';
import { FC, PropsWithChildren, useRef, useState } from 'react';

import { MOCK_MENTIONS } from '../../mock/mentions';
import ContentEditableWithMentions from '../ContentEditableWithMentions';
import { ContentEditableRef } from '../ContentEditableWithMentions/ContentEditableWithMentions.component';
import { StyledPostComment } from './PostComment.styled';

export type PostCommentProps = PropsWithChildren<{
  onSubmit: (message: CommentType[]) => Promise<void>;
}>;

const PostComment: FC<PostCommentProps> = ({ onSubmit }) => {
  const [message, setMessage] = useState<CommentType[]>([]);

  const contentEditableRef = useRef<ContentEditableRef>(null);

  return (
    <StyledPostComment className="esg-post-comment">
      <ContentEditableWithMentions
        ref={contentEditableRef}
        mentions={MOCK_MENTIONS}
        onContentEditableChange={setMessage}
      />
      <Button
        className="esg-post-comment__send"
        icon={<Icon name="paper-plane" />}
        onClick={async () => {
          contentEditableRef?.current?.clearValue?.();
          await onSubmit(message);
        }}
        disabled={
          !message?.length ||
          (message?.length === 1 && message[0].value.trim() === '')
        }
      />
    </StyledPostComment>
  );
};

export default PostComment;

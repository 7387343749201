import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, ButtonProps } from '@faxi/web-component-library';
import classNames from 'classnames';
import { ThreeDotMenu } from 'components/_molecules';
import Icon from 'components/Icon';
import isEmpty from 'lodash.isempty';
import {
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigType,
} from 'models';
import { mapTypeToIcon } from 'pages/Campaigns/utils';
import { FC, memo, PropsWithChildren, useMemo } from 'react';

import { animateLayoutChanges } from '../../utils';
import { StyledCanvasModule } from './CanvasModule.styled';

export const CONFIGURABLE_MODULES = [
  DataModuleEnum.INPUT,
  DataModuleEnum.CHECKLIST,
  DataModuleEnum.CHOICE,
  DataModuleEnum.DROPDOWN,
  DataModuleEnum.TEXT_BOX,
  DataModuleEnum.SWITCH,
  DataModuleEnum.INTEGRATION,
  DataModuleEnum.UPLOAD,
  DataModuleEnum.FORMULA,
] as Array<DataModuleEnum>;

export type CanvasModuleProps = PropsWithChildren<{
  module: ModuleConfig<ModuleConfigType, DataModuleEnum>;
  id: string;
  isGrabbing?: boolean;
  width?: number;
  onClickDelete: (id: string) => void;
  onConfig?: (module: IDataModule) => void;
  onDuplicate?: (module: IDataModule) => void;
  hasUpdatePermission?: boolean;
}>;

const CanvasModule: FC<CanvasModuleProps> = (props) => {
  const {
    module,
    id,
    children,
    isGrabbing,
    width,
    onClickDelete,
    onConfig,
    onDuplicate,
    hasUpdatePermission,
  } = props;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `canvas-module_${module.id}`,
    animateLayoutChanges,
    disabled: !hasUpdatePermission,
  });

  const isConfigurable = useMemo(
    () => CONFIGURABLE_MODULES.includes(module.type),
    [module.type]
  );

  const nodeMenuItems = useMemo(
    () =>
      [
        ...(isConfigurable
          ? [
              {
                children: 'Configure',
                icon: <Icon name="wrench" />,
                variant: 'ghost',
                'data-no-dnd': 'true',
                onClick: (e) => {
                  e.stopPropagation();
                  onConfig?.(module);
                },
              } as ButtonProps,
            ]
          : []),

        {
          children: 'Duplicate',
          icon: <Icon name="copy" />,
          variant: 'ghost',
          'data-no-dnd': 'true',
          onClick: (e) => {
            e.stopPropagation();
            onDuplicate?.(module);
          },
        },
        {
          children: 'Delete',
          icon: <Icon name="trash-can" className="color-secondary" />,
          variant: 'delete-ghost',
          'data-no-dnd': 'true',
          onClick: () => onClickDelete(module.id),
        },
      ] as ButtonProps[],
    [isConfigurable, onDuplicate, module, onConfig, onClickDelete]
  );

  const gripButton = useMemo(
    () => (
      <Button
        icon={<Icon name="grip-dots-vertical" />}
        variant="ghost"
        className="esg-canvas-module__content__grip bypass-draggable"
      />
    ),
    []
  );

  const moduleIcon = useMemo(
    () => <Icon name={mapTypeToIcon[module.type]} />,
    [module.type]
  );

  const threeDotMenu = useMemo(
    () =>
      hasUpdatePermission && (
        <ThreeDotMenu
          data-no-dnd="true"
          className="esg-canvas-module__content__menu"
          menuItems={nodeMenuItems}
          renderAsPortal
        />
      ),
    [hasUpdatePermission, nodeMenuItems]
  );

  return (
    <StyledCanvasModule
      className={classNames(
        'esg-canvas-module',
        `esg-canvas-module--${module.type}`,
        {
          'esg-canvas-module--is-dragging': isDragging || module.isNew,
          'esg-canvas-module--is-grabbing': isGrabbing,
        }
      )}
      width={width}
      ref={setNodeRef}
      id={`canvas-module_${id}`}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
      {...attributes}
      {...listeners}
    >
      <div className="esg-canvas-module__content">
        {gripButton}

        <div className="esg-canvas-module__content__type">{moduleIcon}</div>

        {children}

        {threeDotMenu}
      </div>

      {!isEmpty(module.conditionalElements) && (
        <div className="esg-canvas-module__conditions">
          <Icon name="option" />
          Conditions are applied
        </div>
      )}
    </StyledCanvasModule>
  );
};

export default memo(CanvasModule);

import { UserRole } from './User';

export type Action = 'role-update' | 'mark' | 'assign';
export type MarkedAsStatus = 'Completed' | 'In Review';

export const actionLabels = {
  assign: 'Assigned you to',
  'role-update': 'Updated your role to',
  mark: 'Marked',
};

type BaseNotification = {
  id: string;
  isRead: boolean;
  name: string;
  userRole: UserRole;
  img?: string;
};

export type AssignNotification = BaseNotification & {
  action: 'assign';
  assignedForm: string;
};
export type MarkNotification = BaseNotification & {
  action: 'mark';
  markedAs: `${MarkedAsStatus}`;
  marked: string;
};
export type RoleUpdateNotification = BaseNotification & {
  action: 'role-update';
  updatedRole: UserRole;
};

export type Notification =
  | AssignNotification
  | MarkNotification
  | RoleUpdateNotification;

export type Notifications = Array<{
  date: Date;
  notifications: Array<Notification>;
}>;

import {
  FileUploadModal,
  FileUploadModalProps,
} from '@faxi/web-component-library';
import Button from '@faxi/web-component-library/build/components/_atoms/Button';
import { FieldProps } from '@faxi/web-form';
import { FC, useState } from 'react';

import Icon from '../../Icon';
import { StyledFileUploadModalField } from './FileUploadModalField.styled';

export type FileUploadModalFieldProps = FieldProps<
  File[],
  (files: File[]) => void
> &
  Omit<FileUploadModalProps, 'onFilesAttach'>;

const FileUploadModalField: FC<FileUploadModalFieldProps> = (
  props: FileUploadModalFieldProps
) => {
  const { value, onChange, ...rest } = props;
  const [open, setOpen] = useState(false);

  return (
    <StyledFileUploadModalField>
      <Button
        onClick={() => setOpen(true)}
        variant="outline"
        icon={<Icon name="upload" />}
      >
        {`Upload Documents ${value?.length ? `(${value.length})` : ''}`}
      </Button>
      {open && (
        <FileUploadModal
          {...rest}
          title="Upload document"
          value={value}
          confirmBtnText="Attach documents"
          cancelBtnText="Cancel"
          onFilesAttach={onChange}
          onClose={() => setOpen(false)}
        />
      )}
    </StyledFileUploadModalField>
  );
};

export default FileUploadModalField;

import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../api/routes';
import { BlockUI } from '../../../../../helpers';
import { ApiData, Role } from '../../../../../models';
import { parsePermissionsArray } from '../../../../../utils/getUserInformationFromToken';
import UserRoleHeader from './components/UserRoleHeader';
import UserRolePermissions from './components/UserRolePermissions';
import { USER_ROLE_FORM_DATA } from './data';
import { StyledUserRole } from './UserRole.styled';

type Permissions = ApiData<{ id: string; name: string }[]>;

const UserRole: FC = () => {
  // TODO: change with getRoleById when BE is ready
  const { data: { data: roles } = {} } = useSWR<ApiData<Role[]>, Error>(
    API_ROUTES.USERS.ROLES
  );
  const { roleId = '' } = useParams();
  const userRole = roles?.find((role) => role.id === roleId)!;

  const {
    data: { data } = {},
    isLoading,
    isValidating,
  } = useSWR<Permissions>(API_ROUTES.USERS.PERMISSIONS(roleId));

  const initialData = useMemo(
    () =>
      !data?.length ? {} : parsePermissionsArray(data.map(({ name }) => name)),
    [data]
  );

  return (
    <BlockUI loading={isLoading || isValidating}>
      <StyledUserRole className="esg-user-role">
        <UserRoleHeader
          description={userRole.description}
          name={userRole.name}
          color={userRole.color}
        />
        <UserRolePermissions
          onSubmit={() => Promise.resolve()}
          permissions={USER_ROLE_FORM_DATA}
          initialData={initialData}
        />
      </StyledUserRole>
    </BlockUI>
  );
};

export default UserRole;

import styled from 'styled-components';

import { BaseLayout } from '../../layouts';

const StyledAccountSettings = styled(BaseLayout)`
  button {
    &:hover {
      background-color: unset;
    }
  }
  .esg-account-settings {
  }
`;

export { StyledAccountSettings };

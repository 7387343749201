import { Heading } from '@faxi/web-component-library';
import classNames from 'classnames';
import { SessionCard } from 'components';
import { CampaignSessionTreeNode } from 'models';
import { FC, PropsWithChildren, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Icon from '../../../../components/Icon';
import { MAP_ID, NAVIGATE_TO_MAP } from '../../constants';
import { SessionEntryName } from '../../types';
import { findCurrentSessionEntry, mockProgress } from '../../utils';
import SessionBreadCrumbs from '../SessionBreadCrumbs';
import { StyledSessionReportsEntry } from './SessionReportsEntry.styled';

export type SessionReportsEntryProps = PropsWithChildren<{
  name: SessionEntryName;
}>;

const SessionReportsEntry: FC<SessionReportsEntryProps> = ({ name }) => {
  const params = useParams();

  const navigate = useNavigate();

  const isOrganisation = name === 'organisation';

  // TODO
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sessionTreeWithOrganisationRoot = {};
  // const isLoading = false;
  // const loading = isLoading;

  const currentEntry = useMemo(() => {
    return findCurrentSessionEntry(
      sessionTreeWithOrganisationRoot as CampaignSessionTreeNode,
      params?.[MAP_ID[name]] as string
    );
  }, [name, params, sessionTreeWithOrganisationRoot]);

  return (
    <StyledSessionReportsEntry
      className={classNames('esg-session-reports-entry', {
        'esg-session-reports-entry--loading': false,
      })}
    >
      {!isOrganisation && <SessionBreadCrumbs name={name} />}
      TODO
      <div className="esg-session-reports-entry__header">
        <Icon name={currentEntry?.iconName || 'folder'} />
        <Heading level="1">{currentEntry?.name}</Heading>
      </div>
      {currentEntry?.description && (
        <p className="esg-session-reports-entry__subheader">
          {currentEntry?.description}
        </p>
      )}
      <div className="esg-session-reports-entry__list">
        {(currentEntry?.children ?? []).map((entity: any) => (
          <SessionCard
            onSelect={() => navigate(`${NAVIGATE_TO_MAP[name]}/${entity.id}`)}
            key={entity.id}
            session={{
              ...entity,
              progress: mockProgress(),
              approval: mockProgress(),
            }}
          />
        ))}
      </div>
    </StyledSessionReportsEntry>
  );
};

export default SessionReportsEntry;

import {
  GlowScroll,
  useEffectSkipFirst,
  useUtilities,
} from '@faxi/web-component-library';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { Notifications } from 'models';
import { FC, PropsWithChildren } from 'react';

import { DATE_FORMAT } from '../../../constants';
import NotificationsGrid from '../NotificationsGrid';
import { StyledProfileNotifications } from './ProfileNotifications.styled';

export type ProfileNotificationsProps = PropsWithChildren<{
  notifications: Notifications;
}>;

const ProfileNotifications: FC<ProfileNotificationsProps> = (props) => {
  const { notifications } = props;
  dayjs.extend(isToday);

  const { showSnackBar } = useUtilities();

  //TODO: Remove snackbar when we implement BE for user profile pages
  useEffectSkipFirst(() => {
    showSnackBar({
      variant: 'error',
      text: 'User Notifications are mocked. (Missing BE for functionalities)',
    });
  }, []);

  return (
    <StyledProfileNotifications className="esg-profile-notifications">
      <h2>Notifications</h2>
      {notifications?.map((notificationOnDate, index) => (
        <div className="esg-profile-notifications__on-date" key={index}>
          <div className="esg-profile-notifications__on-date__date">
            {dayjs(notificationOnDate.date).isToday()
              ? 'Today'
              : dayjs(notificationOnDate.date).format(DATE_FORMAT)}
          </div>
          <GlowScroll variant="gray">
            <NotificationsGrid
              notifications={notificationOnDate.notifications}
            />
          </GlowScroll>
        </div>
      ))}
    </StyledProfileNotifications>
  );
};

export default ProfileNotifications;

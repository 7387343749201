import {
  GlowScroll,
  LinkParserWrapper,
  Spinner,
  useCallbackRef,
} from '@faxi/web-component-library';
import { useGetComments } from 'api';
import { API_ROUTES } from 'api/routes';
import { Loading } from 'components';
import {
  ApiData,
  Comment as TypeComment,
  CommentType,
  DataModuleElementDrawerComponentProps,
} from 'models';
import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useMutation from '../../../../../api/hooks/useMutation';
import { useRootProvider } from '../../../../../providers/Root';
import {
  generateOptimisticComment,
  optimisticUpdateCommentsCache,
  updateWithErrorComment,
} from '../../utils';
import Comment from '../Comment';
import PostComment from '../PostComment';
import { StyledModuleElementComments } from './ModuleElementComments.styled';

const ModuleElementComments: FC<DataModuleElementDrawerComponentProps> = ({
  elementId,
  updateNumberOfUnreadComments,
}) => {
  const [showLoading, setShowLoading] = useState<boolean>();

  const { companyId: companySessionId = '', formId = '' } = useParams();

  const {
    items,
    nextPage,
    isLoading,
    mutate: mutateComments,
    isValidating,
    setSize,
  } = useGetComments(companySessionId, formId, elementId, {
    revalidateFirstPage: false,
    revalidateAll: false,
  });

  const { user: currentUser } = useRootProvider();

  const [commentsRef, setCommentsRef] = useCallbackRef<HTMLDivElement>();

  const { trigger } = useMutation<ApiData<TypeComment>>(
    API_ROUTES.COMMENTS.SESSION_FORM_ELEMENT_COMMENTS(
      companySessionId,
      formId,
      elementId
    ),
    { revalidate: false }
  );

  const { trigger: readComments, isMutating } = useMutation<
    ApiData<{ numberOfUnreadComments: number }>
  >(
    API_ROUTES.COMMENTS.SESSION_FORM_ELEMENT_COMMENTS_READ(
      companySessionId,
      formId,
      elementId
    ),
    { revalidate: false }
  );

  const onDeleteFailedComment = useCallback(
    (comment: TypeComment) => {
      mutateComments(
        (prev) =>
          prev?.map((el) => ({
            data: {
              ...el.data,
              items: el.data.items.filter((item) => comment.id !== item.id),
            },
          })),
        {
          revalidate: false,
        }
      );
    },
    [mutateComments]
  );

  const postComment = useCallback(
    async (message: CommentType[]) => {
      try {
        commentsRef?.scroll({ top: 0, behavior: 'smooth' });

        await trigger({
          data: { content: message },
          method: 'POST',
        });
      } catch (e) {
        const comment = generateOptimisticComment(message, currentUser!);

        mutateComments((prev) => updateWithErrorComment(comment, prev), {
          revalidate: false,
        });
      }
    },
    [commentsRef, currentUser, mutateComments, trigger]
  );

  const onPostComment = useCallback(
    async (message: CommentType[]) => {
      const comment = generateOptimisticComment(message, currentUser!);

      mutateComments((prev) => optimisticUpdateCommentsCache(comment, prev), {
        revalidate: false,
      });

      await postComment(message);
    },
    [currentUser, mutateComments, postComment]
  );

  const onRetryComment = useCallback(
    async (comment: TypeComment) => {
      mutateComments(
        (prev) =>
          prev?.map((el) => ({
            data: {
              ...el.data,
              items: el.data.items.filter((item) => item.id !== comment.id),
            },
          })),
        {
          revalidate: false,
        }
      );

      await onPostComment(comment.content);
    },
    [mutateComments, onPostComment]
  );

  const onReachBottom = useCallback(() => {
    if (!commentsRef || isLoading || !nextPage) return;

    const { scrollHeight, offsetHeight, scrollTop } = commentsRef;
    const didReachBottom =
      Math.abs(scrollHeight - offsetHeight - scrollTop) < 1;

    if (didReachBottom && nextPage && !isValidating) {
      setSize((prev) => prev + 1);
    }

    if (!nextPage) {
      setShowLoading(false);
    }
  }, [commentsRef, isLoading, isValidating, nextPage, setSize]);

  useEffect(() => {
    const currentRef = commentsRef;

    if (!currentRef) return;

    currentRef.addEventListener('scroll', onReachBottom);
    return () => {
      currentRef.removeEventListener('scroll', onReachBottom);
    };
  }, [commentsRef, onReachBottom]);

  useEffect(() => {
    if (isValidating && nextPage) {
      setShowLoading(true);
    } else {
      setShowLoading(false);
    }
  }, [isValidating, nextPage]);

  const markAsRead = useCallback(
    async (unreadIds: string[]) => {
      const { data } = await readComments({
        method: 'POST',
        data: {
          readComments: unreadIds,
        },
      });
      await mutateComments();
      updateNumberOfUnreadComments?.(data.numberOfUnreadComments);
    },
    [mutateComments, readComments, updateNumberOfUnreadComments]
  );

  useEffect(() => {
    if (isValidating || isMutating) return;

    const unreadIds: string[] = items
      .filter(({ isRead }) => !isRead)
      .map(({ id }) => id.toString());

    if (unreadIds?.length) {
      markAsRead(unreadIds);
    }
  }, [isMutating, isValidating, items, markAsRead]);

  if (isLoading) return <Loading />;

  return (
    <StyledModuleElementComments className="esg-module-element-comments">
      <GlowScroll variant="gray">
        <div
          ref={setCommentsRef}
          className="esg-module-element-comments__comments"
        >
          {items?.map((comment) => (
            <LinkParserWrapper key={comment.id}>
              <Comment
                comment={comment}
                isError={!!comment?.isError && comment.elementId === 'temp'}
                onDelete={onDeleteFailedComment}
                onRetry={onRetryComment}
              />
            </LinkParserWrapper>
          ))}
          {showLoading && <Spinner size={16} color="#000" />}
        </div>
      </GlowScroll>
      <PostComment onSubmit={onPostComment} />
    </StyledModuleElementComments>
  );
};

export default ModuleElementComments;

import { EmptyFolder, Loading } from 'components';
import { FC, PropsWithChildren, ReactNode } from 'react';

import ErrorFallback from './components/ErrorFallback';

const BlockUI: FC<
  PropsWithChildren & {
    error?: boolean;
    fallback?: ReactNode;
    fallbackCondition?: boolean;
    loading?: boolean;
  }
> = ({
  children,
  error = false,
  fallback = <EmptyFolder title="No data." />,
  fallbackCondition,
  loading,
}) => {
  if (loading) return <Loading />;
  if (error) return <ErrorFallback />;

  return fallbackCondition ? fallback : children;
};

export default BlockUI;

import {
  Button,
  GlowScroll,
  Input,
  useModalUtilities,
  useUtilities,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import { SessionCard } from 'components';
import Icon from 'components/Icon';
import { BlockUI } from 'helpers';
import { CampaignSessionStatus, Session } from 'models';
import { useSessionProvider } from 'providers/Session';
import { FC, MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import useMutation from '../../api/hooks/useMutation';
import { API_ROUTES } from '../../api/routes';
import SessionModal from './components/SessionModal';
import { StyledSessions } from './Sessions.styled';
import { mockProgress } from './utils';

const Sessions: FC = () => {
  const { open, openModal, closeModal } = useModalUtilities();

  const { prompts, showSnackBar } = useUtilities();

  const { isLoading, sessions, search, error, setSearch } =
    useSessionProvider();

  const navigate = useNavigate();

  const { trigger: deleteSession } = useMutation(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE(),
    {
      onSuccess: ({ data }: { data: Pick<Session, 'name'> }) => {
        showSnackBar({
          text: `Successfully deleted ${data.name}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      },
    },
    true
  );

  const onSelectCard = useCallback(
    (session: Session) => {
      const firstIncludedOrganisation = session.settings.find(
        ({ isIncluded }) => !!isIncluded
      );

      const runSessionURL = `${session.id}/run?organisationId=${firstIncludedOrganisation?.organisationId}`;
      const dashboardSessionURL = `${session.id}/details`;

      navigate(
        session.status === CampaignSessionStatus.Draft
          ? runSessionURL
          : dashboardSessionURL
      );
    },
    [navigate]
  );

  const handleDeleteDraftSession = useCallback(
    (session: Session) => async (trigger: MouseEvent<HTMLButtonElement>) => {
      await prompts.delete({
        type: 'delete',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Do not delete',
        title: `Delete ${session.name}`,
        content: `Are you sure you want to delete ${session.name} from sessions?`,
        buttonIcon: 'trash-can',
        iconPosition: 'left',
        confirmButtonVariant: 'delete-ghost',
        titleIcon: <Icon name="triangle-exclamation" />,
        triggerRef: trigger.target as HTMLButtonElement,
        onConfirm: async () => {
          deleteSession({
            method: 'DELETE',
            url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(session.id),
          });
        },
      });
    },
    [deleteSession, prompts]
  );

  return (
    <StyledSessions
      title="Sessions"
      direction="column"
      className="esg-sessions"
      padding={0}
    >
      <GlowScroll variant="gray">
        <div className="esg-sessions__container">
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            onClick={openModal}
          >
            Create a new session
          </Button>
          <div className="esg-sessions__container__filters">
            <Input
              className="esg-sessions__search-input"
              prefixIcon={<Icon name="magnifying-glass" />}
              placeholder="Search"
              {...(search && {
                suffixIcon: (
                  <Button
                    variant="ghost"
                    aria-label="Clear all"
                    icon={<Icon name="xmark" />}
                    onClick={() => setSearch('')}
                  />
                ),
              })}
              value={search}
              onChange={setSearch}
            />
          </div>
          {open && <SessionModal onClose={closeModal} />}
          <div
            className={classNames('esg-sessions__container__list', {
              'esg-sessions__container__list--no-data': !sessions.length,
            })}
          >
            <BlockUI
              loading={isLoading}
              fallbackCondition={!sessions.length}
              error={error}
            >
              {sessions.map((session) => (
                <SessionCard
                  onSelect={() => onSelectCard(session)}
                  key={session.id}
                  onDelete={handleDeleteDraftSession(session)}
                  session={{
                    ...session,
                    ...(session.status === CampaignSessionStatus.Active && {
                      // TODO
                      approval: mockProgress(),
                    }),
                  }}
                />
              ))}
            </BlockUI>
          </div>
        </div>
      </GlowScroll>
    </StyledSessions>
  );
};

export default Sessions;

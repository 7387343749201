import { useUtilities } from '@faxi/web-component-library';
import { AxiosError, AxiosRequestConfig } from 'axios';
import useSWRMutation, { SWRMutationConfiguration } from 'swr/mutation';

import Loading from '../../components/_atoms/Loading';
import axiosInstance from '../axiosInstance';

const useMutation = <TData = void, TError = AxiosError<{ error: string }>>(
  swrMutationKey?: string,
  config?: SWRMutationConfiguration<TData, TError, string, AxiosRequestConfig>,
  withLoadingOverlay = false
) => {
  const { showOverlay, hideOverlay } = useUtilities();

  return useSWRMutation<TData, TError, string | undefined, AxiosRequestConfig>(
    swrMutationKey,
    async (_, { arg: { url = swrMutationKey, ...rest } }) => {
      if (withLoadingOverlay) showOverlay('body', 'fixed', <Loading />);
      try {
        const data = await axiosInstance.request({ url, ...rest });

        return data.data;
      } catch (error) {
        return Promise.reject(error);
      } finally {
        if (withLoadingOverlay) hideOverlay('body');
      }
    },
    config
  );
};

export default useMutation;

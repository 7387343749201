// const apiVersion = env.VITE_API_VERSION;
const apiVersion = 'v1';

const baseUrl = `/users/${apiVersion}`;

//mock because all other routes uses V2
export const USERS = {
  LOGIN: `${baseUrl}/auth`,
  NEW_PASSWORD: `${baseUrl}/password-challenge`,
  USER: `${baseUrl}`,
  USER_ID: (id: string) => `${baseUrl}/${id}`,
  LOGOUT: `${baseUrl}/sign-out`,
  ROLES: `${baseUrl}/roles`,
  USER_ROLES: (id: string) => `${baseUrl}/${id}/roles`,
  REFRESH_TOKEN: `${baseUrl}/refresh-token`,
  PROFILE: `${baseUrl}/profile`,
  CHANGE_PASSWORD: `${baseUrl}/change-password`,
  FORGOT_PASSWORD: `/users/${apiVersion}/forgot-password`,
  RESET_PASSWORD: `/users/${apiVersion}/confirm-forgot-password`,
  PERMISSIONS: (id: string) => `${baseUrl}/roles/${id}/permissions`,
};

import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledFormulaPreview = styled.div`
  ${flex('column', 'center', 'flex-start')};
  gap: ${theme.sizes.SIZE_16};
  width: 100%;

  .esg-formula-preview {
    &__label {
      ${fontSize(theme.fontSizes.FONT_16)};
    }

    &__formula {
      word-break: break-all;
    }
  }
`;

export { StyledFormulaPreview };

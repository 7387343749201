import {
  Button,
  Divider,
  GlowScroll,
  ModalRef,
  useCallbackRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form, FormField, FormRef, validators } from '@faxi/web-form';
import classNames from 'classnames';
import { APP_URI } from 'config';
import dayjs from 'dayjs';
import { BlockUI } from 'helpers';
import cloneDeep from 'lodash.clonedeep';
import {
  CheckStatus,
  OrganisationSession,
  Session,
  TreeNodeElement,
} from 'models';
import {
  FC,
  Fragment,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import {
  BasicTreeView,
  CalendarField,
  CheckboxTreeView,
  EmptyFolder,
  EntityFormModal,
  FolderNavHeader,
  Loading,
  TextareaField,
} from '../../../../components';
import FileUploadField from '../../../../components/_fields/FileUploadField/FileUploadField.component';
import { toggleCheckByIds } from '../../../../components/_organisms/CheckboxTreeView/utils';
import Icon from '../../../../components/Icon';
import useOrganisationTree from '../../../../providers/hooks/useOrganisationTree';
import { useSessionProvider } from '../../../../providers/Session';
import {
  createTreeWithParentReferences,
  mapCampaignToTreeNodeElement,
} from '../../../../utils';
import { NewSessionForm } from '../../../Users/Users.page';
import { StyledRunSession } from './RunSession.styled';

const validations = {
  startDate: validators.general.required('Start date is required'),
  endDate: validators.general.required('Start date is required'),
  campaign: validators.general.required('Campaign is required'),
  companies: validators.general.required('Please select at least one row'),
  exclusionJustification: validators.general.required(
    'Justification is required'
  ),
};

type DataCollectElementForm = {
  exclusionJustification: string;
  justificationFiles: File[];
};

const RunSession: FC = () => {
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { prompts } = useUtilities();

  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  const { mutateSessions } = useSessionProvider();

  const organisationId = useMemo(
    () => searchParams.get('organisationId'),
    [searchParams]
  );

  const { showSnackBar, showOverlay, hideOverlay } = useUtilities();

  const {
    data: { data: session } = {},
    isLoading,
    mutate: mutateSession,
  } = useSWR<{
    data: Session;
  }>(sessionId && API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(sessionId));

  const organisationsIsIncludedMap: Map<string, boolean> | undefined = useMemo(
    () =>
      session?.settings.reduce(
        (acc: Map<string, boolean>, curr) =>
          acc.set(curr.organisationId, curr.isIncluded),
        new Map<string, boolean>()
      ),
    [session]
  );

  const { organisationTree } = useOrganisationTree(
    'sessions',
    organisationsIsIncludedMap
  );

  const [campaignTree, setCampaignTree] = useState<TreeNodeElement>();

  const initialCampaignTree = useRef<TreeNodeElement>();

  const [form, formRef] = useCallbackRef<FormRef>();
  const modalRef = useRef<ModalRef>(null);

  useEffect(() => {
    if (!session) return;

    const campaignTreeWithParentReferences = createTreeWithParentReferences(
      mapCampaignToTreeNodeElement(session.campaign),
      null
    );

    let campaignTreeWithCheckedNodes = campaignTreeWithParentReferences;

    const checkedCampaignItemsIds = session.settings
      .find((el) => el.organisationId === organisationId)
      ?.dataCollectionElements.filter(({ isIncluded }) => isIncluded)
      .map(({ id }) => id);

    if (checkedCampaignItemsIds) {
      campaignTreeWithCheckedNodes = toggleCheckByIds(
        campaignTreeWithParentReferences,
        checkedCampaignItemsIds
      );
    }

    setCampaignTree(campaignTreeWithCheckedNodes);

    initialCampaignTree.current = cloneDeep(campaignTreeWithCheckedNodes);
  }, [session, organisationId]);

  const { trigger: runCurrentSession } = useMutation<{
    data: OrganisationSession;
  }>(
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_RUN(sessionId!),
    {
      onSuccess: ({ data }) => {
        showSnackBar({
          text: `Successfully started ${data.name} session.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
        mutateSessions();
        navigate(`/sessions/${sessionId}/details/dashboard`);
      },
    },
    true
  );

  const handleRunSession = useCallback(
    async (data: Pick<Session, 'startDate' | 'endDate'>) => {
      if (!sessionId) return;

      const { startDate, endDate } = data;

      runCurrentSession({
        method: 'POST',
        data: {
          startDate: dayjs(startDate).toISOString(),
          endDate: dayjs(endDate).toISOString(),
        },
      });
    },
    [sessionId, runCurrentSession]
  );

  const promptRef = useRef<ModalRef>();

  const updateSession = useCallback(async (data: NewSessionForm) => {
    // const sessionData = {
    //   name: data.name,
    //   campaign: data.campaign,
    //   description: data.description,
    // };
    modalRef?.current?.close();
  }, []);

  const { isLoading: isLoadingSessions } = useSWR<{
    data: Session[];
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE());

  const { trigger: updateDataCollectElement } = useMutation('sessions', {
    onSuccess: async () => {
      await mutateSession();

      showSnackBar({
        variant: 'success',
        text: `Data collection element updated successfully.`,
      });

      hideOverlay('body');
    },
    onError: () => hideOverlay('body'),
  });

  const updateDataCollectElementFn = useCallback(
    (
      node: TreeNodeElement,
      data: {
        isIncluded: boolean;
        exclusionJustification?: string;
      }
    ) => {
      if (!sessionId || !organisationId) return;
      showOverlay('body');

      updateDataCollectElement({
        url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_ORGANISATION_DATA_COLLECTION_ELEMENTS(
          sessionId,
          organisationId
        ),
        method: 'POST',
        data: { ...data, campaignItemId: node.id },
      });
    },
    [organisationId, sessionId, showOverlay, updateDataCollectElement]
  );
  //TODO:Change this when api is finished.

  // const uploadJustificationFiles = useCallback(
  //   (
  //     node: TreeNodeElement,
  //     data: {
  //       justificationFiles?: File[];
  //     }
  //   ) => {
  //     if (!sessionId || !organisationId) return;
  //     showOverlay('body');
  //     const files = data.justificationFiles?.map((file) => ({
  //       fileName: file.name,
  //       fileType: file.type,
  //     }));

  //     updateDataCollectElement({
  //       url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSIONS_FILES_UPLOAD(
  //         sessionId
  //       ),
  //       method: 'POST',
  //       data: { files, campaignItemId: node.id },
  //     });
  //   },
  //   [organisationId, sessionId, showOverlay, updateDataCollectElement]
  // );

  const formWrapper = useCallback(
    (node: TreeNodeElement) =>
      ({ children, className }: PropsWithChildren<{ className: string }>) => (
        <Form
          children={children}
          className={className}
          onSubmit={async ({
            exclusionJustification,
            justificationFiles,
          }: DataCollectElementForm) => {
            updateDataCollectElementFn(node, {
              isIncluded: false,
              exclusionJustification,
            });
            // uploadJustificationFiles(node, {
            //   justificationFiles,
            // });
            promptRef.current?.close();
          }}
        />
      ),
    [updateDataCollectElementFn]
  );

  return (
    <StyledRunSession
      direction="column"
      title="Sessions"
      className="esg-run-session"
    >
      {/* TODO: even though loading is true, children seem to try to render, thus erroring the UI. For example, try session!.name below */}
      <BlockUI loading={isLoading || isLoadingSessions}>
        <FolderNavHeader
          crumbs={[
            { text: 'Sessions', href: APP_URI.SESSIONS },
            { text: session?.name || '', href: location.pathname },
          ]}
          title={session?.name || ''}
          description={session?.description}
          updateDescription={() => modalRef.current?.open()}
          icon="chart-bar-solid"
        />

        <Divider className="esg-run-session__divider" />

        <div className="esg-run-session__content">
          <GlowScroll variant="gray">
            <div
              className={classNames(
                'esg-run-session__content__container',
                'esg-run-session__content__container--left'
              )}
            >
              <div
                className="esg-run-session__content__header"
                aria-describedby="organisation-description"
              >
                Organisation
              </div>
              <div id="organisation-description">
                All the companies in your organisations are included in this
                session by default. Hover over a company to exclude it from the
                session.
              </div>

              {organisationTree && organisationId ? (
                <BasicTreeView
                  withMenu
                  data={{
                    ...organisationTree,
                  }}
                  showRootNode={false}
                  activeNodeId={organisationId}
                />
              ) : (
                <Loading />
              )}
            </div>
          </GlowScroll>

          <Divider orientation="vertical" />

          <GlowScroll variant="gray">
            <div
              className={classNames(
                'esg-run-session__content__container',
                'esg-run-session__content__container--right'
              )}
            >
              <div
                className="esg-run-session__content__header"
                aria-describedby="campaign-description"
              >
                Campaign:
                <div className="esg-run-session__content__header__button">
                  <Icon name="leaf" />
                  <span>{session?.campaignName}</span>
                </div>
              </div>
              <div id="campaign-description">
                All the topics and subtopics of the selected campaign are
                included in this session by default. You can exclude specific
                topics and subtopics for each company using the checkboxes
              </div>
              {campaignTree ? (
                <CheckboxTreeView
                  filteredData={campaignTree}
                  onCheck={async (node, checked, e) => {
                    try {
                      if (checked === CheckStatus.Unchecked) {
                        await prompts.standard({
                          type: 'standard',
                          confirmButtonText: 'Submit',
                          cancelButtonText: 'Cancel',
                          title: `Exclude ${node.name}`,
                          childrenWrapper: formWrapper(node),
                          ref: promptRef,
                          content: (
                            <Fragment>
                              <FormField
                                label="Why are you excluding this item?"
                                component={TextareaField}
                                name="exclusionJustification"
                                placeholder="Justification"
                                verticalResize
                                required
                                requiredLabel="Required"
                                validate={validations.exclusionJustification}
                              />
                              <FormField
                                component={FileUploadField}
                                name="justificationFiles"
                                acceptedFileTypesText="Accepted file types: "
                                maxFileSize={100}
                                multiple={true}
                                maxFiles={3}
                                dropZoneText="Drag & drop files here or "
                                clickToUploadText="click to upload"
                                uploadingText="Uploading"
                                filesCountError="Maximum number of files exceeded. Max allowed is 3."
                                fileTypeError="File type not allowed"
                                fileSizeError="File size too large"
                                alreadyExistError="File with this name already exists"
                                acceptedFileTypes={[
                                  'application/pdf',
                                  'image/png',
                                  'image/jpeg',
                                  'video/quicktime',
                                  'text/plain',
                                ]}
                              />
                            </Fragment>
                          ),
                          confirmButtonType: 'submit',
                          triggerRef: e?.target,
                        });
                      } else {
                        updateDataCollectElementFn(node, {
                          isIncluded: true,
                        });
                      }
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                />
              ) : (
                <div className="esg-company__campaign__no-data">
                  <EmptyFolder title="No attached campaigns." iconName="file" />
                </div>
              )}
            </div>
          </GlowScroll>
        </div>

        <Divider className="esg-run-session__divider" />

        <Form
          onSubmit={handleRunSession}
          ref={formRef}
          className="esg-run-session__form"
        >
          <div className="esg-run-session__dates">
            Dates
            <div className="esg-run-session__dates__container">
              <FormField
                name="startDate"
                component={CalendarField}
                validate={validations.startDate}
                placeholder="Set start date"
                disablePast
              />
              <FormField
                name="endDate"
                component={CalendarField}
                validate={validations.endDate}
                placeholder="Set end date"
                disablePast
              />
            </div>
          </div>
          <div className="esg-run-session__buttons">
            <Button
              type="submit"
              disabled={!form?.syncFormValid || !form?.isFormChanged()}
            >
              Start Session
            </Button>
          </div>
        </Form>

        <EntityFormModal
          ref={modalRef}
          renderAsPortal
          className="esg-company-modal"
          onClose={() => modalRef.current?.close()}
          initialData={session}
          title={`Edit ${session?.name} session`}
          onSubmit={updateSession}
          fieldsConfiguration={{
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            TYPE: false,
            CAMPAIGN: true,
          }}
        />
      </BlockUI>
    </StyledRunSession>
  );
};

export default RunSession;

/* eslint-disable no-loop-func */
import classNames from 'classnames';
import { BasicTreeView } from 'components';
import { TreeNodeElement } from 'models';
import { ChangeEvent, FC, useCallback } from 'react';

import { TreeNodeProps } from '../../_molecules/BasicTreeView/components';
import { StyledCheckboxTreeView } from './CheckboxTreeView.styled';
import { toggleCheck } from './utils';

export type CheckboxTreeViewProps = {
  filteredData: TreeNodeElement;
  className?: string;
  onCheck?: TreeNodeProps['onCheck'];
};

const CheckboxTreeView: FC<CheckboxTreeViewProps> = ({
  filteredData,
  className,
  onCheck,
}) => {
  const handleCheck = useCallback(
    (
      node: TreeNodeElement,
      checked: TreeNodeElement['checked'],
      e: ChangeEvent<HTMLInputElement> | undefined
    ) => {
      toggleCheck(node, checked);

      onCheck?.(node, checked, e);
    },
    [onCheck]
  );

  return (
    <StyledCheckboxTreeView
      className={classNames('esg-checkbox-tree-view', className)}
    >
      <BasicTreeView
        data={filteredData}
        onCheck={onCheck ? handleCheck : undefined}
      />
    </StyledCheckboxTreeView>
  );
};

export default CheckboxTreeView;

import { Button, Divider, SelectOption } from '@faxi/web-component-library';
import {
  FieldArray,
  FormContext,
  FormField,
  useFormContextValues,
  validators,
} from '@faxi/web-form';
import { InputField, SelectField, SwitchField } from 'components';
import { Each } from 'helpers';
import { DropdownDataModule, DropdownModuleConfig } from 'models';
import { FC, useContext, useMemo } from 'react';
import { generateOptionsByLabels } from 'utils';

import { SelectFieldProps } from '../../../../../components/_fields/SelectField/SelectField.component';
import Icon from '../../../../../components/Icon';
import { useRemoveConditionalModule } from '../../../../../hooks';
import { generateUniqueId } from '../../../../../utils/generateUID';
import { SimpleInputField } from '../BaseInputFieldConfiguration/components/SimpleInput/SimpleInput.component';
import { StyledBaseDropdownFieldConfiguration } from './BaseDropdownFieldConfiguration.styled';

export type BaseDropdownFieldConfigurationProps = DropdownDataModule;

const BaseDropdownFieldConfiguration: FC<
  BaseDropdownFieldConfigurationProps
> = ({ hasConditions = true, id, config, renderModuleField }) => {
  const { updateValueField } = useContext(FormContext);
  const value = useFormContextValues('options', 'defaultValue') as {
    options: SelectOption[];
    defaultValue: string;
  };

  const defaultOptions = useMemo(
    () => generateOptionsByLabels(value?.options),
    [value?.options]
  );

  const { handleRemoveModule } = useRemoveConditionalModule(id);

  return (
    <StyledBaseDropdownFieldConfiguration className="esg-dropdown-field-configuration">
      <FieldArray name="options">
        {(config) => (
          <>
            <Each
              containerAs="div"
              className="esg-dropdown-field-configuration__options"
              of={config.fields}
              render={(field, index) => {
                return (
                  <div className="esg-dropdown-field-configuration__option">
                    <p>{`Option ${index + 1}`}</p>
                    <FormField
                      name={`${field.name}.label`}
                      autoComplete="off"
                      component={InputField}
                      placeholder="Enter Option"
                      validate={validators.general.required(
                        'Option is required'
                      )}
                    />
                    {renderModuleField?.(field.value.key)}
                    <Button
                      variant="ghost"
                      className="remove-btn"
                      onClick={() => {
                        hasConditions && handleRemoveModule(field.value.key);

                        config.remove(index);

                        const isDefaultValueSelected =
                          value.defaultValue &&
                          field.value.label === value.defaultValue;

                        if (isDefaultValueSelected)
                          updateValueField('defaultValue', '');
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                );
              }}
            />

            <Button
              variant="ghost"
              icon={<Icon name="plus" />}
              aria-label="Add option"
              onClick={() => {
                config.add({ label: '', key: generateUniqueId() });
              }}
              className="esg-ghost-button-padded"
            >
              Add Option
            </Button>
          </>
        )}
      </FieldArray>

      <Divider />

      <FormField
        promptSwitch
        name="other"
        label="Show 'Other' option"
        component={SwitchField}
        promptModalProps={{
          confirmButtonText: 'OK',
          title: "Show 'Other' option",
          content:
            "By selecting 'Other' option users will " +
            'be allowed to enter free text to specify.',
        }}
      />

      <Divider />

      <div className="esg-dropdown-field-configuration__content">
        <SimpleInputField<DropdownModuleConfig, SelectFieldProps>
          switchLabel="Default selected option"
          configKey="defaultValue"
          placeholder="Default value"
          renderAsPortal
          component={SelectField}
          options={defaultOptions}
          config={config as DropdownModuleConfig}
        />

        <SimpleInputField<DropdownModuleConfig>
          switchLabel="Tooltip helper text"
          configKey="helperText"
          placeholder="Helper text"
          config={config as DropdownModuleConfig}
        />

        <SimpleInputField<DropdownModuleConfig>
          switchLabel="Custom placeholder"
          configKey="placeholder"
          placeholder="Placeholder"
          config={config as DropdownModuleConfig}
        />

        <SimpleInputField
          switchLabel="XBRL Tag"
          configKey="xbrl"
          placeholder="XBRL"
          config={config as DropdownModuleConfig}
        />
      </div>
    </StyledBaseDropdownFieldConfiguration>
  );
};

export default BaseDropdownFieldConfiguration;

import { useUtilities } from '@faxi/web-component-library';
import { useEffect } from 'react';

import LoadingStyled from '../components/_atoms/Loading';

const useOverlay = (
  isOverlayActive: boolean,
  LoadingComponent: React.ReactNode = <LoadingStyled />
) => {
  const { showOverlay, hideOverlay } = useUtilities();

  useEffect(() => {
    isOverlayActive
      ? showOverlay('body', 'center', LoadingComponent)
      : hideOverlay('body');
  }, [hideOverlay, isOverlayActive, showOverlay, LoadingComponent]);
};

export default useOverlay;

import { Button, Image } from '@faxi/web-component-library';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { APP_URI } from '../../../config';
import { UserRoleName } from '../../../models';
import { useRootProvider } from '../../../providers/Root';
import { StyledErrorFallback } from './ErrorFallback.styled';

// TODO: fine-tune this when roles/permissions finished
const mapRoleToHomePage: Record<UserRoleName, keyof typeof APP_URI> = {
  'super admin': 'SESSIONS',
  'basic user': 'PROFILE',
  'org admin': 'ORGANISATIONS',
  'auditor': 'SESSIONS',
};

const ErrorFallback: FC = () => {
  const navigate = useNavigate();

  const { user } = useRootProvider();

  const role = useMemo<UserRoleName>(() => user.roles[0].name, [user]);

  return (
    <StyledErrorFallback className="esg-error-screen">
      <Image src="/assets/svg/robot.svg" alt="error" />
      <h1>Something went wrong.</h1>
      <p>Looks like an error occurred, we're resolving it.</p>
      <Button onClick={() => navigate(APP_URI[mapRoleToHomePage[role]])}>
        Go to home page
      </Button>
    </StyledErrorFallback>
  );
};

export default ErrorFallback;

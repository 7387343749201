import { useState } from 'react';

import useDebouncedValue from './useDebouncedValue';

const useSearch = () => {
  const [search, setSearch] = useState<string>('');

  const debouncedSearch = useDebouncedValue(search, 500);

  return { search, debouncedSearch, setSearch };
};

export default useSearch;

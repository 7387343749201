import { FC, Fragment, PropsWithChildren } from 'react';

import { AccessPermissions, PermissionSections } from '../../../models';
import { useRootProvider } from '../../../providers/Root';

export type ViewGuardProps = PropsWithChildren<{
  section: PermissionSections;
  permissions: AccessPermissions[];
}>;

/**
 *
 * Component that renders its content if user have permission or dont render content if user is missing permissions
 * @param props.section - represents the standalone entity that have its own permission set.For example (USER, CAMPAIGN,OGRAGANISATION,SESSION);
 */
const ViewGuard: FC<ViewGuardProps> = (props) => {
  const { children, section, permissions } = props;

  const { userTokenInformation: { permissions: userPermissions } = {} } =
    useRootProvider();

  const doesUserHaveSectionPermissions = Object.entries(userPermissions ?? {})
    .filter(([_, value]) => value.length)
    .map(([key]) => key)
    .includes(section);

  if (!doesUserHaveSectionPermissions) {
    return null;
  }

  const hasPermission = permissions.some((per) =>
    userPermissions?.[section].includes(per)
  );

  return <Fragment>{hasPermission ? children : null}</Fragment>;
};

export default ViewGuard;

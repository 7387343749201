import { FC } from 'react';

import { BlockUI } from '../../../../helpers';
import useSessionDashboardGridCards from '../../hooks/useSessionDashboardGridCards';
import { SessionDashboardCard } from './components';
import { StyledSessionDashboard } from './SessionDashboard.styled';

const SessionDashboard: FC = () => {
  const {
    data: sessionDashboardData,
    isLoading,
    error,
  } = useSessionDashboardGridCards();

  return (
    <StyledSessionDashboard
      title="Organisations"
      className="esg-session-dashboard"
      direction="column"
    >
      <BlockUI loading={isLoading} fallbackCondition={error}>
        <div className="esg-session-dashboard__content">
          {sessionDashboardData?.map((data) => (
            <SessionDashboardCard key={data.id} {...data} />
          ))}
        </div>
      </BlockUI>
    </StyledSessionDashboard>
  );
};

export default SessionDashboard;

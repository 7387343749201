import { FC, PropsWithChildren } from 'react';

import { DataModuleElementDrawerComponentProps } from '../../../../../models';
import { StyledModuleElementDetails } from './ModuleElementDetails.styled';

export type ModuleElementDetailsProps = PropsWithChildren<{}>;

//TODO
const ModuleElementDetails: FC<DataModuleElementDrawerComponentProps> = () => {
  return (
    <StyledModuleElementDetails className="esg-module-element-details">
      hello from ModuleElementDetails!
    </StyledModuleElementDetails>
  );
};

export default ModuleElementDetails;

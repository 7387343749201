import { createSearchParams, NavigateFunction } from 'react-router-dom';

import { Organisation, TreeNodeElement } from '../models';

export const mapOrganisationToTreeNodeElement = (
  { id, name, description, createdAt, updatedAt, children }: Organisation,
  depth: number,
  navigate: NavigateFunction,
  type: 'organisations' | 'sessions',
  organisationsInclusionMap: Map<string, boolean> | undefined,
  includeCompany: (organisationId: string) => void,
  excludeCompany: (organisationId: string) => void
): TreeNodeElement => {
  const isNodeIncluded = !!organisationsInclusionMap?.get(id);

  return {
    id,
    name,
    description,
    createdAt,
    updatedAt,
    children: children?.map((child) =>
      mapOrganisationToTreeNodeElement(
        child,
        depth + 1,
        navigate,
        type,
        organisationsInclusionMap,
        includeCompany,
        excludeCompany
      )
    ),
    iconName: 'folder-open',
    to:
      type === 'organisations'
        ? id
        : {
            pathname: '',
            search: `${createSearchParams({
              organisationId: id,
            })}`,
          },
    ...(type === 'sessions' && {
      action: {
        icon: isNodeIncluded ? 'ban' : 'plus',
        message: isNodeIncluded ? 'Exclude' : 'Include',
        showOnHover: isNodeIncluded,
        disabledStyle: !isNodeIncluded,
        onClick: (e) => {
          e.stopPropagation();
          isNodeIncluded ? excludeCompany(id) : includeCompany(id);
        },
      },
    }),
  };
};

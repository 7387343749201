import { SelectOption, Tag } from '@faxi/web-component-library';
import { FormField } from '@faxi/web-form';
import { FormulaDataModule, IDataModule } from 'models';
import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../../api/routes';
import TagsInputField from '../../../../../components/_fields/TagsInputField';
import { useSearch } from '../../../../../hooks';
import { StyledBaseFormulaFieldConfiguration } from './BaseFormulaFieldConfiguration.styled';

export type BaseFormulaFieldConfigurationProps = FormulaDataModule;
const BaseFormulaFieldConfiguration: FC<BaseFormulaFieldConfigurationProps> = ({
  config: { formula } = {},
}) => {
  const { campaign = '' } = useParams<{
    campaign: string;
  }>();

  const { debouncedSearch, setSearch } = useSearch();

  const {
    data: { data: dataCollectionElements } = { data: [] },
    isLoading,
    isValidating,
  } = useSWR<{
    data?: IDataModule[];
  }>([
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.CAMPAIGN_DATA_COLLECTION_ELEMENTS(
      campaign
    ),
    {
      ...(debouncedSearch && {
        params: { searchString: debouncedSearch },
      }),
    },
  ]);

  const tagsOptions = useMemo<SelectOption[]>(
    () =>
      dataCollectionElements?.map(({ title, id }) => ({
        label: title,
        value: id,
      })) || [],
    [dataCollectionElements]
  );

  return (
    <StyledBaseFormulaFieldConfiguration className="esg-formula-field-configuration">
      <FormField
        component={TagsInputField}
        placeholder="Type here"
        withOperators
        toolboxIcon="calculator-simple"
        name="formula"
        tagsOptions={tagsOptions}
        loading={isLoading || isValidating}
        onSearchChange={setSearch}
        onChange={() => setSearch('')}
        validate={[
          (tags: Tag[]) => {
            if (!tags.length && Number(formula?.length) > 0) {
              return 'This field is required.';
            }

            if (tags && tags.some((t) => t.error)) {
              return 'Please resolve the following errors:';
            }

            return '';
          },
        ]}
      />
    </StyledBaseFormulaFieldConfiguration>
  );
};

export default BaseFormulaFieldConfiguration;

import { PermissionSections } from 'models';

import { EsgSidebarItem } from '../layouts/Sidebar/types';
import { hasRouteAllPermissions } from './hasRouteAllPermissions';

export const sidebarItemsPermissionBased = (
  sideBarMainItems: EsgSidebarItem[],
  userRouterPermissions?: PermissionSections[]
) =>
  sideBarMainItems.filter(
    ({ permissionGroup }) =>
      !permissionGroup ||
      (userRouterPermissions &&
        hasRouteAllPermissions(userRouterPermissions, permissionGroup))
  );

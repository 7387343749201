import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, position, size } from '@faxi/web-css-utilities';
import styled, { css } from 'styled-components';

interface StyledUserCardProps {
  $view: 'grid' | 'list';
}
const emailStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const StyledUserCard = styled.div<StyledUserCardProps>`
  ${elevate_l};

  width: 100%;
  cursor: pointer;
  position: relative;
  border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_8);
  padding: ${theme.sizes.SIZE_32};
  border-radius: ${theme.sizes.SIZE_8};
  gap: ${theme.sizes.SIZE_8};
  max-width: ${theme.sizes.SIZE_320};
  display: grid;

  &.esg-user-card {
    &--list {
      max-width: unset;
    }
  }

  &:hover {
    transition: none !important;
  }

  .user-image {
    .esg-basic-tag {
      ${position('absolute', `left 50% bottom -${theme.sizes.SIZE_8}`)};
      transform: translate(-50%);
      width: fit-content;
      padding-top: ${theme.sizes.SIZE_4};
    }
  }

  ${(props) =>
    props.$view === 'list'
      ? css`
          min-width: ${theme.sizes.SIZE_374};
          grid-template-columns: auto 1fr;
          grid-template-rows: auto auto;
          align-items: center;
          justify-items: flex-start;
          column-gap: ${theme.sizes.SIZE_20};

          .user-image {
            grid-column: 1;
            grid-row: 1 / 3;
            ${size(theme.sizes.SIZE_112)};

            .user-image-container,
            img {
              ${size(theme.sizes.SIZE_112)};
              min-width: ${theme.sizes.SIZE_112};
            }

            .wcl-icon {
              ${fontSize(theme.sizes.SIZE_88)};
            }

            .esg-basic-tag {
              ${fontSize(theme.sizes.SIZE_12, theme.sizes.SIZE_12)};
            }
          }
        `
      : css`
          justify-items: center;
          text-align: center;
          gap: ${theme.sizes.SIZE_16};
          .user-image {
            ${size(theme.sizes.SIZE_152)};
            .user-image-container,
            img {
              ${size(theme.sizes.SIZE_152)};
            }

            .wcl-icon {
              ${fontSize(theme.sizes.SIZE_120)};
            }

            .esg-basic-tag {
              ${fontSize(theme.sizes.SIZE_16, theme.sizes.SIZE_16)};
            }
          }
        `};

  .esg-user-card {
    &__menu {
      ${position(
        'absolute',
        `top ${theme.sizes.SIZE_16} right ${theme.sizes.SIZE_16}`
      )};
      z-index: 1;
    }

    &__name {
      ${fontSize(theme.fontSizes.FONT_20)};
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      ${(props) =>
        props.$view === 'list'
          ? css`
              grid-column: 2;
              grid-row: 1;
              max-width: ${theme.sizes.SIZE_296};
            `
          : css`
              max-width: ${theme.sizes.SIZE_240};
            `};
    }

    &__description {
      ${flex('row', 'center', 'center')};
      gap: ${theme.sizes.SIZE_10};
      ${fontSize(theme.fontSizes.FONT_16)};
      color: var(--BLACK) !important;
      text-decoration: unset !important;

      ${(props) =>
        props.$view === 'grid' &&
        css`
          span {
            max-width: ${theme.sizes.SIZE_184};
            ${emailStyle}
          }
        `}

      .wcl-icon {
        margin-top: ${theme.sizes.SIZE_2};
        svg {
          ${fontSize(theme.fontSizes.FONT_18)};
        }
      }

      .envelope-icon {
        margin-top: ${theme.sizes.SIZE_4};
      }
    }
  }

  ${(props) =>
    props.$view === 'list' &&
    css`
      @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        justify-items: center;
        .user-image,
        .esg-user-card__name,
        .esg-user-card__description {
          grid-column: 1;
          grid-row: auto;
        }

        gap: ${theme.sizes.SIZE_16};
        text-align: center;

        .esg-user-card__description {
          span {
            max-width: ${theme.sizes.SIZE_256};
            ${emailStyle}
          }
        }
      }
    `};

  .esg-user-card__delete-btn {
    height: fit-content;
    min-height: unset;
    ${position(
      'absolute',
      `bottom ${theme.sizes.SIZE_12} right ${theme.sizes.SIZE_12}`
    )}
  }
`;

export { StyledUserCard };

import { Session } from 'models';
import { useSessionProvider } from 'providers/Session';
import React, { useContext, useMemo } from 'react';

import { mockProgress } from '../../utils';
import SelectedSessionContext from './SelectedSession.context';

type SelectedSessionProviderProps = {
  sessionId: string;
  children: React.ReactNode;
};

const SelectedSessionProvider: React.FC<SelectedSessionProviderProps> = ({
  sessionId,
  children,
}) => {
  const { sessions } = useSessionProvider();

  const selectedSession = useMemo(() => {
    const session = sessions.find((session) => session.id === sessionId);

    return {
      ...session,
      approval: mockProgress(),
    };
  }, [sessions, sessionId]);

  if (!selectedSession) return <></>;

  return (
    <SelectedSessionContext.Provider value={selectedSession as Session}>
      {children}
    </SelectedSessionContext.Provider>
  );
};
const useSelectedSession = () => useContext(SelectedSessionContext);

export { SelectedSessionProvider, useSelectedSession };

import { useModalUtilities, useUtilities } from '@faxi/web-component-library';
import {
  CampaignItem,
  DataModuleEnum,
  IDataModule,
  ModuleConfig,
  ModuleConfigMapperType,
  ModuleConfigType,
} from 'models';
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { KeyedMutator } from 'swr';

import useGetCampaignItem from '../../../../api/hooks/useGetCampaignItem';
import { findModule } from '../../../../pages/Campaigns/components/Dnd/utils';
import ModuleConfiguration from '../../../../pages/Campaigns/components/ModuleConfiguration';
import CampaignItemContext from '../../../../pages/Campaigns/context/CampaignItem/CampaignItem.context';
import { useFormBuilder } from '../../../../pages/Campaigns/context/FormBuilder';
import {
  dataModuleTextMapper,
  prepareCampaignFormObject,
} from '../../../../pages/Campaigns/utils';
import modifyModules from '../../../../utils/modifyModules';

type ModuleActionsContextType = {
  campaignItem?: CampaignItem;
  isLoading: boolean;
  mutate: KeyedMutator<{
    data: CampaignItem;
  }>;
  handleDuplicateModule: (module: IDataModule) => Promise<void>;
  handleDeleteModule: (module: IDataModule) => Promise<void>;
  handleUpdateModule: (
    module: ModuleConfig<ModuleConfigType, DataModuleEnum>
  ) => Promise<void>;
  handleConfigModule: (
    module: ModuleConfig<ModuleConfigType, DataModuleEnum>
  ) => void;
};

const ModuleActionsContext = createContext<
  ModuleActionsContextType | undefined
>(undefined);

type ModuleActionsProviderProps = {
  children: ReactNode;
  configurableModules: ModuleConfigMapperType;
  level: 1 | 2 | 3;
};

export const ModuleActionsProvider: FC<ModuleActionsProviderProps> = ({
  level,
  configurableModules,
  children,
}) => {
  const {
    subsubtopic = '',
    subsubsubtopic = '',
    subsubsubsubtopic = '',
  } = useParams<{
    subsubtopic: string;
    subsubsubtopic: string;
    subsubsubsubtopic: string;
  }>();

  const { editCampaignItem, duplicateCampaignItem } =
    useContext(CampaignItemContext);

  const { campaignItem, isLoading, mutate } = useGetCampaignItem(
    level === 1 ? subsubtopic : level === 2 ? subsubsubtopic : subsubsubsubtopic
  );

  const { open, openModal, closeModal } = useModalUtilities();

  const [activeModuleConfig, setActiveModuleConfig] =
    useState<ModuleConfig<ModuleConfigType, DataModuleEnum>>();

  const { modules, setModules } = useFormBuilder();
  const { showOverlay, hideOverlay, showSnackBar } = useUtilities();

  const handleDuplicateModule = useCallback(
    async (module: IDataModule) => {
      showOverlay('body');

      try {
        await duplicateCampaignItem(module);

        showSnackBar({
          text: `Successfully duplicated ${dataModuleTextMapper[module.type].toLowerCase()}${module.title ? ' ' + module.title : ''}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [duplicateCampaignItem, hideOverlay, showOverlay, showSnackBar]
  );

  const handleDeleteModule = useCallback(
    async (module: IDataModule) => {
      showOverlay('body');

      const newArr = modifyModules.deleteModuleById(modules, module);

      try {
        await editCampaignItem(prepareCampaignFormObject(newArr));
        setModules(newArr);

        showSnackBar({
          text: `Successfully deleted ${dataModuleTextMapper[module.type].toLowerCase()}${module.title ? ` ${module.title}` : ''}.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay('body');
      }
    },
    [
      editCampaignItem,
      hideOverlay,
      modules,
      setModules,
      showOverlay,
      showSnackBar,
    ]
  );

  const handleUpdateModule = useCallback(
    async (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) => {
      const updatedModules = modifyModules.updateModuleById(modules, module);

      const moduleSelector = `[id='canvas-module_${module.id}']`;

      try {
        showOverlay(moduleSelector);

        await editCampaignItem(prepareCampaignFormObject(updatedModules));
        setModules(updatedModules);

        showSnackBar({
          text: `Successfully updated ${module.type} module.`,
          variant: 'success',
          actionButtonText: 'Dismiss',
        });
      } catch (e) {
        console.error(e);
      } finally {
        hideOverlay(moduleSelector);
      }
    },
    [
      modules,
      showOverlay,
      editCampaignItem,
      setModules,
      showSnackBar,
      hideOverlay,
    ]
  );

  const handleConfigModule = useCallback(
    (module: IDataModule<DataModuleEnum>) => {
      setActiveModuleConfig(findModule(module.id, modules).module);
      openModal();
    },
    [modules, openModal]
  );

  return (
    <ModuleActionsContext.Provider
      value={{
        campaignItem,
        isLoading,
        mutate,
        handleDuplicateModule,
        handleDeleteModule,
        handleUpdateModule,
        handleConfigModule,
      }}
    >
      {children}
      {open && activeModuleConfig && (
        <ModuleConfiguration
          moduleConfig={activeModuleConfig}
          onSubmit={handleUpdateModule}
          onClose={closeModal}
          configurableModules={configurableModules}
        />
      )}
    </ModuleActionsContext.Provider>
  );
};

export const useModuleActionsProvider = () => {
  const context = useContext(ModuleActionsContext);
  if (!context) {
    throw new Error(
      'useModuleActionsProvider must be used within a ModuleActionsProvider'
    );
  }
  return context;
};

import { SelectOption } from '@faxi/web-component-library';
import { FC, ReactNode, useContext, useState } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../api/routes';
import { useOverlay, useSearch } from '../../hooks';
import { Session } from '../../models';
import SessionsDataContext, { SessionContextProps } from './Session.context';

type SessionProviderProps = {
  children?: ReactNode;
};

const SessionProvider: FC<SessionProviderProps> = (props) => {
  const { children } = props;

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<SelectOption>();

  const { setSearch, debouncedSearch } = useSearch();

  const {
    data: { data: sessions } = {},
    isLoading,
    mutate: mutateSessions,
    error: errorSessions,
    isValidating,
  } = useSWR<{
    data: Session[];
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE(debouncedSearch));

  useOverlay(isValidating && !isLoading);

  return (
    <SessionsDataContext.Provider
      value={{
        sessions: sessions || [],
        search: debouncedSearch,
        selectedOrganisation,
        setSearch,
        setSelectedOrganisation,
        error: !!errorSessions,
        isLoading: isLoading,
        mutateSessions,
      }}
    >
      {children}
    </SessionsDataContext.Provider>
  );
};

const useSessionProvider = (): SessionContextProps =>
  useContext(SessionsDataContext);

export { SessionProvider, useSessionProvider };

import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize, position, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSessionCard = styled.div`
  ${elevate_l};
  ${flex('row')};
  flex-wrap: wrap;
  gap: ${theme.sizes.SIZE_12};
  cursor: pointer;
  padding: ${theme.sizes.SIZE_32};
  border-radius: ${theme.sizes.SIZE_8};
  max-width: ${theme.sizes.SIZE_912};
  position: relative;

  .esg-session-card {
    &__menu {
      ${position(
        'absolute',
        `top ${theme.sizes.SIZE_16} right ${theme.sizes.SIZE_16}`
      )};
      z-index: 1;
    }

    &__column {
      ${flex('column', 'flex-start', 'flex-start')};
      gap: ${theme.sizes.SIZE_12};
      padding-right: ${theme.sizes.SIZE_24};

      &:last-of-type {
        margin: auto 0 auto auto;
      }

      &--name {
        margin-right: ${theme.sizes.SIZE_20};
      }

      &__name {
        ${fontSize(theme.fontSizes.FONT_24)};
        font-weight: 600;
        ${flex('row', 'center', 'center')};
        gap: ${theme.sizes.SIZE_8};

        &__icon {
          margin-top: ${theme.sizes.SIZE_8};

          svg {
            ${size(theme.sizes.SIZE_20)};
          }
        }
      }

      &__description {
        ${fontSize(theme.fontSizes.FONT_14)};
        padding-left: 30px;
        margin: 0;
        color: var(--GRAY-54);
      }
    }
  }
`;

export { StyledSessionCard };

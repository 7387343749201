import { OrganisationSession } from 'models';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../api/routes';
import { INameExtended } from '../../../components/Icon';

const DEFAULT_SESSION_REPORT_APPROVAL_DATA: {
  icon: INameExtended;
  name: string;
  description: string;
} = {
  icon: 'building',
  name: 'Approval Status',
  description: 'by Company',
};

const useSessionDashboardGridCards = () => {
  const { sessionId } = useParams<{ sessionId: string }>();

  const { data: { data } = {}, ...rest } = useSWR<{
    data: OrganisationSession[];
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSIONS(sessionId!));

  const sessionOrganisations = useMemo(
    () =>
      data?.map(
        ({
          organisation: { name, description },
          progress,
          campaignItems,
          id,
          campaignId,
        }) => ({
          ...DEFAULT_SESSION_REPORT_APPROVAL_DATA,
          items: campaignItems.filter(({ parent }) => parent.id === campaignId),
          name,
          description,
          id,
          progress,
        })
      ),
    [data]
  );

  return { ...rest, data: sessionOrganisations };
};

export default useSessionDashboardGridCards;

import {
  Breadcrumbs,
  Button,
  Divider,
  Heading,
} from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { ApprovalProgressBar } from '../../../../components';
import { DATE_FORMAT } from '../../../../constants';
import { useSelectedSession } from '../../context/SelectedSession';
import SessionSidebarLink, {
  SessionSidebarLinkProps,
} from './components/SessionSidebarLink/SessionSidebarLink.component';
import { StyledSessionSidebar } from './SessionSidebar.styled';
// import { mapAPISessionToTreeNode, SessionTreeNodeElement } from './utils';

const SESSION_SIDEBAR_LINKS: SessionSidebarLinkProps[] = [
  {
    url: APP_URI.SESSION_SESSION_DETAILS_DASHBOARD,
    text: 'Dashboard',
    leftIcon: 'grid',
  },
  {
    url: APP_URI.SESSION_SESSION_DETAILS_ACTIVITY,
    text: 'Activity',
    leftIcon: 'wave-pulse',
  },
  {
    url: APP_URI.SESSION_SESSION_DETAILS_MEMBERS,
    text: 'Contributors',
    leftIcon: 'users',
  },
  {
    url: APP_URI.SESSION_SESSION_DETAILS_REPORT,
    text: 'Campaign browser',
    leftIcon: 'folder-tree',
    rightIcon: 'chevron-right',
  },
];

const SessionSidebar: FC = () => {
  const selectedSession = useSelectedSession();

  const location = useLocation();

  // const { sessionTreeWithOrganisationRoot, isLoading, error } =
  //   useGetSessionTree(sessionId);

  // const isReportSelected = location.pathname.includes(APP_URI.SESSION_REPORT);

  // TODO
  // const sessionTreeWithOrganisationRoot = {};
  // const isLoading = false;
  // const error = true;

  // const tree = useMemo(() => {
  //   if (!error && sessionTreeWithOrganisationRoot) {
  //     const basePath = `/sessions/${sessionId}/report`;

  //     return mapAPISessionToTreeNode(
  //       sessionTreeWithOrganisationRoot as unknown as SessionTreeNodeElement,
  //       basePath,
  //       location
  //     );
  //   }
  //   return undefined;
  // }, [error, sessionTreeWithOrganisationRoot, sessionId, location]);

  return (
    <StyledSessionSidebar
      className="esg-session-sidebar"
      expandDirection="right"
    >
      <div className="esg-session-sidebar__top">
        <Breadcrumbs
          splitter="/"
          crumbs={[
            { text: 'Sessions', href: APP_URI.SESSIONS },
            { text: selectedSession!.name, href: location.pathname },
          ]}
        />

        <Heading level="1">{selectedSession!.name}</Heading>

        <Divider />

        <div className="esg-session-sidebar__top__details">
          <div className="esg-session-sidebar__top__details__date">
            <div>Session started</div>
            <div>{dayjs(selectedSession?.startDate).format(DATE_FORMAT)}</div>
          </div>
          <div className="esg-session-sidebar__top__details__date">
            <div>Session ends</div>
            <div>{dayjs(selectedSession?.endDate).format(DATE_FORMAT)}</div>
          </div>
          <ApprovalProgressBar
            title="Progress"
            status="In Progress"
            progress={selectedSession?.progress}
          />
          <ApprovalProgressBar
            title="Approval"
            status="In Progress"
            // TODO
            // {...selectedSession!.approval}
          />
        </div>
        <Divider />

        <p className="esg-session-sidebar__top__subtitle">Browser</p>

        <div className="esg-session-sidebar__top__links">
          {SESSION_SIDEBAR_LINKS.map((link) => (
            <SessionSidebarLink key={link.url} {...link} />
          ))}
        </div>
      </div>

      <div className="esg-session-sidebar__bottom">
        <Button
          className="esg-session-sidebar__bottom__download"
          variant="outline"
          icon={<Icon name="download" />}
          disabled
        >
          Download Report
        </Button>
      </div>
    </StyledSessionSidebar>
  );
};

export default SessionSidebar;

import { CommentType, UserMention } from 'models';

/**
 * @param node element in witch we are calculating offset of cursor
 * @returns offset of cursor from beggining of node as length of text for corresponding range
 */

export const getCursorPosition = (node: Node) => {
  const selection = window.getSelection();
  if (!selection || !selection.rangeCount) return 0;

  const range = selection.getRangeAt(0);

  const clonedRange = range.cloneRange();

  clonedRange.selectNodeContents(node);

  clonedRange.setEnd(range.endContainer, range.endOffset);

  return clonedRange.toString().length;
};

export const setCursorPositionOnEndOfMention = (mention: Element) => {
  const selection = window.getSelection();
  if (!selection || !mention.childNodes?.length) return;

  const range = document.createRange();

  range.setEnd(
    mention.childNodes[0],
    mention.childNodes[0]?.textContent?.length || 0
  );
  selection?.removeAllRanges();
  selection?.addRange(range);
  selection?.collapseToEnd();
};

/**
 *
 * @param node node for witch we create range
 * @param targetPosition value of getCursorPosition function
 * @returns Calculate in witch container is target position and set as end of returning range
 */

export const createRange = (node: Node, targetPosition: number) => {
  const range = document.createRange();

  // Handle standalone TextNode without a parent
  if (node.nodeType === Node.TEXT_NODE) {
    range.selectNodeContents(node);
    range.setStart(node, 0);
  } else {
    range.selectNode(node);
    range.setStart(node, 0);
  }

  let pos = 0;
  const stack = [node];
  while (stack.length > 0) {
    const current = stack.pop();

    if (current?.nodeType === Node.TEXT_NODE) {
      const len = current.textContent?.length ?? 0;
      if (pos + len >= targetPosition) {
        range.setEnd(current, targetPosition - pos);
        return range;
      }
      pos += len;
    } else if (current?.childNodes && current.childNodes?.length > 0) {
      for (let i = current.childNodes.length - 1; i >= 0; i--) {
        stack.push(current.childNodes[i]);
      }
    }
  }

  range.setEnd(node, pos);
  return range;
};

/**
 *
 * @param node Element in witch we set cursor position
 * @param targetPosition value of getCursorPosition function
 *
 * This function set range to selection and collapseRangeToEnd so that cursor position is at the end of range
 */

export const setPosition = (node: Node, targetPosition: number) => {
  const range = createRange(node, targetPosition);
  const selection = window.getSelection();
  if (selection && range) {
    selection.removeAllRanges();
    selection.addRange(range);
    selection.collapseToEnd();
  }
};

/**
 *
 * @returns Information about current mention is selection
 */

export const getMentionInformation = () => {
  const { anchorNode } = window.getSelection() || {};
  const { parentElement } = anchorNode || {};

  if (!parentElement || parentElement.nodeName !== 'SPAN') return;

  return {
    showDropdown: true,
    filterText: parentElement.innerText.slice(1),
    spanEl: parentElement,
  };
};

/**
 *
 * @param element element for witch we calculate position
 * @returns x and y coordinates of element on screen so we know where we render dropdown
 */

export const calculateElementPositionOnScreen = (
  element: Element,
  dropdown: Element
) => {
  const elementRect = element.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;

  let dropdownHeight = 0;
  let dropdownWidth = 0;

  if (dropdown) {
    const dropdownRect = dropdown.getBoundingClientRect();
    dropdownHeight = dropdownRect.height;
    dropdownWidth = dropdownRect.width;
  }

  // Space available below and above the element
  const spaceBelow = viewportHeight - (elementRect.y + elementRect.height);
  const spaceAbove = elementRect.y;

  let x = elementRect.x;
  let y = elementRect.y + elementRect.height;

  // Enough space below, render below
  if (spaceBelow >= dropdownHeight) {
    y = elementRect.y + elementRect.height;
  }

  // Enough space above, render above
  else if (spaceAbove >= dropdownHeight) {
    y = elementRect.y - dropdownHeight;
  }
  // Not enough space either way, default to below but ensure it fits
  else {
    y = Math.max(0, viewportHeight - dropdownHeight);
  }

  // Adjust for horizontal overflow
  if (x + dropdownWidth > viewportWidth) {
    x = viewportWidth - dropdownWidth;
  } else if (x < 0) {
    x = 0;
  }

  return { x, y };
};

/**
 *
 * @param name
 * @param mention
 * @returns Compare name and mention
 */

export const compareNameAndMention = (name: string, mention: string) => {
  const mentionPart = mention.replaceAll(/\s/g, ' ').slice(1).toLowerCase();

  const regex = new RegExp(mentionPart.split('').join('.*'));

  return regex.test(name.toLowerCase());
};

//TODO: UPDATE FUNCTION TO have as prop array of mentions when BE is ready
/**
 *
 * @param mention
 * @returns is contained in mensions
 */

export const isValidMention = (mention: string, mentions: UserMention[]) => {
  return mentions.some((user) => compareNameAndMention(user.name, mention));
};

export const checkFullName = (mention: string, name: string) => {
  return (
    mention.slice(1).replaceAll(/\s/g, ' ') === name.replaceAll(/\s/g, ' ')
  );
};

export const isFullMention = (mention: string, allMentions: any[]) =>
  allMentions.some((user) => checkFullName(mention, user.name));

/**
 *
 * @param nodeList List of child nodes in content Editable
 * @param mentions List of all possible mentions
 * @returns Returns the parsed array with type of text and value (mention or plain text)
 */
export const parseContentEditableValue = (
  nodeList: NodeListOf<ChildNode | Element>,
  mentions: Array<{ id: string; name: string }>
): CommentType[] => {
  const array = [] as CommentType[];

  for (let i = 0; i < nodeList.length; i++) {
    const node = nodeList[i];
    const value = node.textContent ?? '';

    if (node instanceof Element) {
      const currentMention = mentions.find(
        (mention) => mention.id === (node as HTMLSpanElement).dataset.id
      );

      if (currentMention) {
        array.push({
          meta: {
            type: 'mention',
            entityId: currentMention.id,
          },
          value: currentMention.name,
        });
      }
    } else {
      if (value) array.push({ meta: { type: 'text' }, value });
    }
  }
  return array;
};

// Identify the current element where the cursor resides
export const findElementInSelection = () => {
  const selection = window.getSelection();
  const currentNode = selection?.anchorNode;
  return currentNode?.parentElement as HTMLElement;
};

const createSpan = (content: string, id?: string) => {
  const newSpan = document.createElement('span');
  newSpan.className = 'mention';
  if (id) {
    newSpan.setAttribute('data-id', id);
  }
  newSpan.textContent = content;

  return newSpan;
};

//hande if cursor is in spanWithId
export const spanWithId = (
  element: HTMLElement,
  mentions: UserMention[],
  contentEditable: HTMLDivElement
) => {
  const mentionName = element.innerText;

  const matchedMention = mentions.find(
    (mention) => mention.id === element.dataset.id
  );

  if (matchedMention) {
    const fullNameMatches = isFullMention(mentionName, mentions);

    // SUBCASE 1: Mention full name is not matching
    if (!fullNameMatches) {
      if (
        mentionName.length > matchedMention.name.length + 1 &&
        mentionName.includes(`@${matchedMention.name}`)
      ) {
        const extraText = mentionName.split(`@${matchedMention.name}`);
        const newSpan = createSpan(
          `@${matchedMention.name}`,
          element.dataset.id
        );
        contentEditable.replaceChild(newSpan, element);

        if (mentionName.startsWith(`@${matchedMention.name}`)) {
          const extraTextNode = document.createTextNode(extraText[1]);

          if (newSpan.nextSibling) {
            contentEditable.insertBefore(extraTextNode, newSpan.nextSibling);
          } else {
            contentEditable.appendChild(extraTextNode);
          }
        }
        if (mentionName.endsWith(`@${matchedMention.name}`)) {
          const extraTextNode = document.createTextNode(extraText[0]);
          contentEditable.insertBefore(extraTextNode, newSpan);
        }
      } else if (isValidMention(mentionName, mentions)) {
        const newSpan = createSpan(mentionName);
        contentEditable.replaceChild(newSpan, element);
      } else {
        const textNode = document.createTextNode(mentionName);
        contentEditable.replaceChild(textNode, element);
      }
    }
  }
};

export const textElement = (
  textNode: Node,
  element: HTMLElement,
  mentions: UserMention[]
) => {
  const textContent = textNode.textContent || '';

  if (textContent.includes('@')) {
    const matches = [...textContent.matchAll(/(@\w+(\s\w*)?)/g)];

    if (matches.length === 0) return;

    const fragment = document.createDocumentFragment();

    let lastIndex = 0;

    matches.forEach((match) => {
      const mention = match[0];
      const mentionStart = match.index || 0;

      // Add text before the mention as a plain text node
      if (mentionStart > lastIndex) {
        const beforeText = textContent.slice(lastIndex, mentionStart);
        fragment.appendChild(document.createTextNode(beforeText));
      }

      // Add valid mention as span
      if (isValidMention(mention, mentions)) {
        const span = createSpan(mention);
        fragment.appendChild(span);
      } else {
        // Add the invalid mention as plain text
        fragment.appendChild(document.createTextNode(mention));
      }

      lastIndex = mentionStart + mention.length;
    });

    // Add any remaining text after the last mention
    if (lastIndex < textContent.length) {
      const afterText = textContent.slice(lastIndex);
      fragment.appendChild(document.createTextNode(afterText));
    }

    element.replaceChild(fragment, textNode);
  }
};

export const spanWithoutId = (
  element: HTMLElement,
  mentions: UserMention[],
  contentEditable: HTMLDivElement
) => {
  const potentialMentionName = element.innerText;

  const matchingMentions = mentions.filter((m) =>
    compareNameAndMention(m.name, potentialMentionName)
  );

  // SUBCASE 1: One match and full name matches
  if (
    matchingMentions.length === 1 &&
    isFullMention(potentialMentionName, matchingMentions)
  ) {
    const matchingMention = matchingMentions[0];
    element.dataset.id = matchingMention.id;
    return matchingMention;
  }

  // SUBCASE 2: No matches found
  else if (matchingMentions.length < 1) {
    const textNode = document.createTextNode(potentialMentionName);
    contentEditable.replaceChild(textNode, element);
  }
};

//reset - remove current mentions span
export const replaceSpanWithoutId = (container: HTMLElement): void => {
  Array.from(container.querySelectorAll('span.mention:not([data-id])')).forEach(
    (span) => {
      container.replaceChild(
        document.createTextNode(span.textContent || ''),
        span
      );
    }
  );
};

import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize, pxToRem } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledContentEditableWithMentions = styled.div`
  ${focusStyles};

  flex: 1;
  border-radius: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8} 0 0;

  .esg-content-editable-with-mentions {
    &__editable-container {
      ${fontSize(theme.fontSizes.FONT_14)};
      position: relative;
      min-height: ${theme.sizes.SIZE_56};
      max-height: ${theme.sizes.SIZE_120};
      padding: ${theme.sizes.SIZE_20} ${theme.sizes.SIZE_10} ${theme.sizes.SIZE_4};
      min-width: 0;
      width: 100%;
      overflow: auto;
      overflow-wrap: anywhere;

      border: ${theme.sizes.SIZE_1} solid var(--SHADE_1_5);
      border-bottom: ${theme.sizes.SIZE_1} solid var(--SHADE_1_2);
      border-radius: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_8} 0 0;
      background-color: var(--SHADE_1_9);
      line-height: normal;
      resize: vertical;

      &::-webkit-scrollbar {
        width: ${theme.sizes.SIZE_8};
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--GRAY-98);
        border-radius: ${theme.sizes.SIZE_4};
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &:focus {
        outline: 1px solid var(--ACCENT_1_1);
        border-bottom: ${theme.sizes.SIZE_2} solid var(--ACCENT_1_1);
        padding-bottom: ${pxToRem('11px')};
      }

      &:before {
        content: 'Message...';
        position: absolute;
        top: 14px;
        left: 10px;
        font-size: ${theme.fontSizes.FONT_14};
        pointer-events: none;
        transition: all 0.2s ease-in-out;
      }

      &:focus-within:before,
      &:not(:empty):before {
        top: ${pxToRem('4px')};
        font-size: ${theme.fontSizes.FONT_11};
        color: var(--ACCENT_1_1);
      }
      .mention {
        color: var(--BLUE-00);
        position: relative;
      }

      .multiple {
        color: var(--RED-EB);
      }
    }
  }
`;

const MentionsDropdownContainer = styled.div<{
  $x?: number;
  $y?: number;
  $show: boolean;
}>`
  ${flex('column')};

  min-width: ${theme.sizes.SIZE_256};
  gap: ${theme.sizes.SIZE_12};
  position: fixed;
  border-radius: ${theme.sizes.SIZE_6};
  border: 1px solid #ddd;
  z-index: 10;
  top: ${({ $y }) => `${$y ?? 0}px`};
  left: ${({ $x }) => `${$x ?? 0}px`};
  box-shadow: 0px 3px 9px 0px #38434b1f;
  background-color: white;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};

  :not(:first-child) {
    z-index: 2;
  }

  .active {
    background-color: var(--GRAY-EF);
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1;
`;

export {
  MentionsDropdownContainer,
  StyledContentEditableWithMentions,
  StyledOverlay,
};

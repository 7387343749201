import {
  Comment,
  CommentType,
  PaginatedApiData,
  User,
} from '../../../../models';
import { generateUniqueId } from '../../../../utils/generateUID';

export const generateOptimisticComment = (
  message: CommentType[],
  author: User
): Comment => ({
  id: generateUniqueId(),
  author,
  content: message,
  authorId: author.id,
  elementId: 'temp',
  createdAt: new Date().toISOString(),
  isRead: true,
});

export const optimisticUpdateCommentsCache = (
  comment: Comment,
  commentsApiData?: PaginatedApiData<Comment>[]
): PaginatedApiData<Comment>[] | undefined => {
  if (!commentsApiData?.length) return;

  return [
    {
      data: {
        ...commentsApiData[0].data,
        items: [comment, ...commentsApiData[0].data.items.slice()],
      },
    },
    ...commentsApiData.slice(1),
  ];
};

export const updateWithErrorComment = (
  comment: Comment,
  commentsApiData?: PaginatedApiData<Comment>[]
): PaginatedApiData<Comment>[] | undefined => {
  if (!commentsApiData?.length) return;

  return [
    {
      data: {
        ...commentsApiData[0].data,
        items: [
          { ...comment, isError: true },
          ...commentsApiData[0].data.items.slice(1),
        ],
      },
    },
    ...commentsApiData.slice(1),
  ];
};

import {
  Breadcrumbs,
  Heading,
  Image,
  Spinner,
} from '@faxi/web-component-library';
import { ApprovalProgressBar, BasicTreeView } from 'components';
import {
  CampaignItem,
  FormStatus,
  Organisation,
  Progress,
  SessionForm,
} from 'models';
import { FC, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { API_ROUTES } from '../../../../api/routes';
import { BlockUI } from '../../../../helpers';
import { OrganisationSessionCampaignItem } from './components/CompanyTreeNode/CompanyTreeNode.component';
import { StyledSessionDashboardCompany } from './SessionDashboardCompany.styled';
import {
  extendOrganisationSessionCampaignItemNodes,
  ORGANISATION_SESSION_MOCK_CONTRIBUTORS_PROGRESS,
} from './treeViewData';

type SessionCompanyPageParams = { companyId: string; sessionId: string };

const SessionDashboardCompany: FC = () => {
  const { companyId, sessionId } = useParams<SessionCompanyPageParams>();
  const isOrganisationReadyRef = useRef<boolean>(false);

  const {
    data: { data } = {},
    isValidating,
    isLoading,
    error,
  } = useSWR<{
    data: {
      organisation: Organisation;
      campaignItems: CampaignItem[];
      campaignId: string;
      campaignName: string;
      forms: SessionForm[];
      progress: Progress;
    };
  }>(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION(companyId!), {
    refreshInterval: !isOrganisationReadyRef.current ? 5000 : undefined,
    dedupingInterval: 5000,
  });

  // TODO: clean up this code when all finished
  const organisationSessionCampaignTree = useMemo(() => {
    if (!data) return undefined;

    const findCampaignById = (
      id: string,
      campaignTree: OrganisationSessionCampaignItem
    ): OrganisationSessionCampaignItem | undefined => {
      if (campaignTree.id === id) return campaignTree;

      if (campaignTree.children) {
        for (const child of campaignTree.children) {
          const res = findCampaignById(id, child);

          if (res) {
            return res;
          }
        }
      }

      return undefined;
    };

    const campaignTree: OrganisationSessionCampaignItem = {
      name: data.campaignName,
      id: data.campaignId,
      progress: data.progress,
      children: [],
    };

    const campaignItemsMap: Map<string, CampaignItem> = new Map();

    for (const campaignItem of data.campaignItems) {
      campaignItemsMap.set(campaignItem.id, campaignItem);
    }

    let isOrganisationReady = true;

    while (campaignItemsMap.size) {
      for (const [campaignItemId, campaignItem] of campaignItemsMap) {
        const parent = findCampaignById(campaignItem.parent.id, campaignTree);

        if (parent) {
          const form = data.forms.find(
            ({ dataCollectionElementId }) =>
              dataCollectionElementId === campaignItemId
          );

          const isPreparingForm =
            !form &&
            !campaignItem.children?.length &&
            campaignItem.type === 'data_collection_element';

          if (isPreparingForm) isOrganisationReady = false;

          parent.children?.push({
            ...campaignItem,
            ...(!form && { children: [] }),
            ...(form && {
              to: `${campaignItemId}?name=${campaignItem.name}`,
            }),
            ...(isPreparingForm && {
              element: (
                <div className="esg-session-dashboard-company__is-preparing-node">
                  Preparing form... <Spinner size={20} color="#808080" />
                </div>
              ),
              children: undefined,
              disabled: true,
            }),
            contributors:
              ORGANISATION_SESSION_MOCK_CONTRIBUTORS_PROGRESS[
                Math.floor(
                  Math.random() *
                    ORGANISATION_SESSION_MOCK_CONTRIBUTORS_PROGRESS.length
                )
              ].contributors,
            progress: campaignItem?.progress,
          });
        }

        campaignItemsMap.delete(campaignItemId);
      }
    }

    isOrganisationReadyRef.current = isOrganisationReady;

    return extendOrganisationSessionCampaignItemNodes(campaignTree);
  }, [data]);

  return (
    <StyledSessionDashboardCompany className="esg-session-dashboard-company">
      <BlockUI loading={isLoading || isValidating} error={error}>
        <Breadcrumbs
          className="esg-session-dashboard-company__breadcrumbs"
          splitter="/"
          crumbs={[
            {
              text: 'Dashboard',
              href: `/sessions/${sessionId}/details/dashboard`,
            },
            { text: data?.organisation?.name!, href: '' },
          ]}
        />

        <div className="esg-session-dashboard-company__header">
          <div className="esg-session-dashboard-company__header__text">
            <Image src="/assets/images/kinto-join.png" alt="Kinto Join logo" />
            <Heading level="1">{data?.organisation?.name}</Heading>
          </div>

          <div className="esg-session-dashboard-company__header__progress">
            <ApprovalProgressBar
              title="Progress"
              progress={organisationSessionCampaignTree?.progress}
              status={
                organisationSessionCampaignTree?.progress?.progress === 100
                  ? FormStatus.Completed
                  : FormStatus.InProgress
              }
            />
            <ApprovalProgressBar
              title="Approval"
              progress={{ completed: 0, progress: 0, total: 0 }}
              status={FormStatus.InProgress}
            />
          </div>
        </div>

        <div className="esg-session-dashboard-company__subheaders">
          <small>Section</small>
          <small>Contributors</small>
          <small>Status</small>
        </div>
        <BasicTreeView data={organisationSessionCampaignTree} />
      </BlockUI>
    </StyledSessionDashboardCompany>
  );
};

export default SessionDashboardCompany;

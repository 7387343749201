import { useUtilities } from '@faxi/web-component-library';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';

import useMutation from '../../api/hooks/useMutation';
import { API_ROUTES } from '../../api/routes';
import { useSWRCache } from '../../hooks';
import { Organisation } from '../../models';
import { mapOrganisationToTreeNodeElement } from '../../utils';
import { useRootProvider } from '../Root';

const useOrganisationTree = (
  type: 'sessions' | 'organisations',
  organisationsInclusionMap?: Map<string, boolean>
) => {
  const { user } = useRootProvider();

  const navigate = useNavigate();

  const {
    data: { data: rootOrganisation } = {},
    error,
    mutate: mutateOrganisations,
  } = useSWR<{
    data: Organisation;
  }>(
    user?.organisation.id &&
      API_ROUTES.ORGANISATIONS.ORGANISATION_TREE(user.organisation.id)
  );

  const { showSnackBar } = useUtilities();
  const { sessionId } = useParams<{
    sessionId: string;
  }>();

  const { mutateCacheKeys } = useSWRCache();

  const { trigger: includeCompany, isMutating: isIncludingCompany } =
    useMutation(
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(sessionId!),
      {
        onSuccess: () => {
          mutateCacheKeys((key) =>
            [API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE()].includes(key)
          );

          showSnackBar({
            text: 'Organisation successfully added to this session.',
            variant: 'success',
            actionButtonText: 'Dismiss',
          });
        },
      },
      true
    );

  const { trigger: excludeCompany, isMutating: isExcludingCompany } =
    useMutation(
      API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.SESSION(sessionId!),
      {
        onSuccess: () => {
          mutateCacheKeys((key) =>
            [API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE()].includes(key)
          );
          showSnackBar({
            text: 'Organisation successfully removed from this session.',
            variant: 'success',
            actionButtonText: 'Dismiss',
          });
        },
      },
      true
    );

  const organisationTree = useMemo(
    () =>
      rootOrganisation &&
      mapOrganisationToTreeNodeElement(
        rootOrganisation,
        0,
        navigate,
        type,
        organisationsInclusionMap,
        (id) =>
          includeCompany({
            url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_INCLUDE(
              sessionId!,
              id
            ),
            method: 'POST',
          }),
        (id) =>
          excludeCompany({
            url: API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.CAMPAIGN_SESSION_EXCLUDE(
              sessionId!,
              id
            ),
            method: 'POST',
          })
      ),
    [
      excludeCompany,
      includeCompany,
      navigate,
      organisationsInclusionMap,
      rootOrganisation,
      sessionId,
      type,
    ]
  );

  return {
    organisationTree,
    rootOrganisation,
    error,
    isLoading: isIncludingCompany || isExcludingCompany,
    mutateOrganisations,
  };
};

export default useOrganisationTree;

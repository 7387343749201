import { GlowScroll, useUtilities } from '@faxi/web-component-library';
import api from 'api';
import { APP_URI } from 'config';
import { BlockUI } from 'helpers';
import { FC, useCallback } from 'react';
import useSWR from 'swr';

import { API_ROUTES } from '../../api/routes';
import { useSWRCache } from '../../hooks';
import { Campaign } from '../../models';
import CampaignEntity from './components/CampaignEntity';
import { useCampaignItem } from './context/CampaignItem';

const Campaigns: FC = () => {
  const {
    data: { data: campaigns } = { data: [] },
    error,
    mutate,
    isLoading,
  } = useSWR<{ data: Campaign[] }>(API_ROUTES.CAMPAIGN_ITEMS_ROUTES.BASE());

  const { trigger, isMutating } = api.useMutation(
    API_ROUTES.CAMPAIGN_ITEMS_ROUTES.BASE()
  );

  const { campaignItem, mutating, editCampaignItem } = useCampaignItem();

  const { showSnackBar } = useUtilities();
  const { mutateCacheKeys } = useSWRCache();

  const navigateTo = useCallback(
    (id: string) => APP_URI.CAMPAIGNS_CAMPAIGN.replace(':campaign', id),
    []
  );

  const createOrUpdateCampaign = useCallback(
    async (data: Record<string, any>) => {
      if (campaignItem?.id) {
        await editCampaignItem({ description: data.description });

        mutate({
          data: campaigns?.map((el) =>
            el.id === campaignItem.id
              ? { ...el, desciption: data.description }
              : el
          ),
        });
      } else {
        await trigger({ method: 'POST', data });
      }

      mutateCacheKeys((key) =>
        key.startsWith(API_ROUTES.CAMPAIGN_ITEMS_ROUTES.BASE())
      );

      showSnackBar({
        text: `${data.name} campaign successfully ${campaignItem?.id ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [
      campaignItem?.id,
      campaigns,
      mutateCacheKeys,
      editCampaignItem,
      mutate,
      trigger,
      showSnackBar,
    ]
  );
  return (
    <BlockUI
      loading={isLoading}
      fallbackCondition={!campaigns?.length}
      error={!!error}
    >
      <GlowScroll variant="gray">
        <CampaignEntity
          navigateTo={navigateTo}
          defaultItems={campaigns}
          entityType="campaign"
          modalProps={{ loading: mutating || isMutating }}
          onSubmit={createOrUpdateCampaign}
        />
      </GlowScroll>
    </BlockUI>
  );
};

export default Campaigns;

const APP_URI = {
  BASE: '/',

  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_SIGN_UP: '/auth/signup',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_RESET_PASSWORD: '/auth/reset-password',

  ACCOUNT_SETTINGS: '/account-settings',

  NEW_PASSWORD_REQUIRED: '/setup-password',

  DASHBOARD: '/dashboard',

  CAMPAIGNS: '/campaigns',
  CAMPAIGNS_CAMPAIGN: '/campaigns/:campaign',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC: '/campaigns/:campaign/topics/:topic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic/subsubsubtopics/:subsubsubtopic',
  CAMPAIGNS_CAMPAIGN_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC_SUBSUBSUBTOPICS_SUBSUBSUBTOPIC_SUBSUBSUBSUBTOPICS_SUBSUBSUBSUBTOPIC:
    '/campaigns/:campaign/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic/subsubsubtopics/:subsubsubtopic/subsubsubsubtopics/:subsubsubsubtopic',

  ORGANISATIONS: '/organisations',
  ORGANISATIONS_ORGANISATION: '/organisations/:organisationId',

  SESSIONS: '/sessions',
  SESSIONS_SESSION: ':sessionId',
  SESSION_SESSION_RUN: 'run',
  SESSION_SESSION_DETAILS: 'details',
  SESSION_SESSION_DETAILS_DASHBOARD: 'dashboard',
  SESSION_SESSION_DETAILS_DASHBOARD_COMPANY: ':companyId',
  SESSION_SESSION_DETAILS_DASHBOARD_COMPANY_FORM: ':formId',
  SESSION_SESSION_DETAILS_ACTIVITY: 'activity',
  SESSION_SESSION_DETAILS_MEMBERS: 'members',
  SESSION_SESSION_DETAILS_REPORT: 'report',

  SESSION_REPORT_COMPANY: 'companies/:company',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC: 'companies/:company/topics/:topic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic',
  SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC_SUBSUBTOPICS_SUBSUBTOPIC:
    'companies/:company/topics/:topic/subtopics/:subtopic/subsubtopics/:subsubtopic',
  USERS: '/users',
  USER_ROLES: '/users/user-roles',
  USER_BY_ID_PROFILE: '/users/:userId',
  ADMIN: '/admin',

  PROFILE: '/profile',
  PERSONAL_DETAILS: '/profile/personal-details',
  NOTIFICATIONS: '/profile/notifications',
  ASSIGNMENTS: '/profile/assignments',
};

export { APP_URI };

import { theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledChecklistPreview = styled.div`
  ${flex('column', 'space-between', 'flex-start')};
  gap: ${theme.sizes.SIZE_20};
  width: 100%;

  .esg-checklist-preview {
    &__content {
      width: 100%;

      > div {
        gap: ${theme.sizes.SIZE_10};
      }
    }
  }

  & span {
    ${fontSize(theme.fontSizes.FONT_14)}
  }
`;

export { StyledChecklistPreview };

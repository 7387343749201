import {
  Divider,
  OverlayRef,
  TabOption,
  Tabs,
} from '@faxi/web-component-library';
import { NotificationBadgeWrapper } from 'components';
import { DataModuleElementDrawerComponentProps, IDataModule } from 'models';
import {
  FC,
  forwardRef,
  ForwardRefRenderFunction,
  PropsWithChildren,
  useMemo,
  useState,
} from 'react';

import Icon from '../../../../../components/Icon';
import ModuleElementActivity from '../ModuleElementActivity';
import ModuleElementComments from '../ModuleElementComments';
import ModuleElementDetails from '../ModuleElementDetails';
import { StyledModuleElementDrawer } from './ModuleElementDrawer.styled';

export type ModuleElementDrawerProps = PropsWithChildren<{
  module?: IDataModule;
}>;

export type ModuleElemetDrawerTab = 'activity' | 'comments' | 'details';

export const TAB_MAPPER: Record<
  ModuleElemetDrawerTab,
  FC<DataModuleElementDrawerComponentProps>
> = {
  activity: ModuleElementActivity,
  comments: ModuleElementComments,
  details: ModuleElementDetails,
};

const ModuleElementDrawer: ForwardRefRenderFunction<
  OverlayRef,
  ModuleElementDrawerProps
> = (props, ref) => {
  const { module: { title, id = '', numberOfUnreadComments } = {} } = props;

  const [activeTab, setActiveTab] = useState<ModuleElemetDrawerTab>('comments');
  const [newNumberOfUnreadComments, setNewNumberOfUnreadComments] =
    useState<number>();

  const DrawerSection = TAB_MAPPER[activeTab];

  const drawerTabs: TabOption<ModuleElemetDrawerTab>[] = useMemo(
    () => [
      {
        iconName: 'wave-pulse-regular',
        label: 'Activity Log',
        value: 'activity',
      },
      //TODO: add props when BE for unread comments is ready
      {
        iconNode: (
          <NotificationBadgeWrapper
            hasNotification={
              (newNumberOfUnreadComments ?? numberOfUnreadComments ?? 0) > 0
            }
            count={newNumberOfUnreadComments ?? numberOfUnreadComments}
          >
            <Icon name="message" />
          </NotificationBadgeWrapper>
        ),
        label: 'Comments',
        value: 'comments',
      },
      {
        iconName: 'plus',
        label: 'Details',
        value: 'details',
      },
    ],
    [newNumberOfUnreadComments, numberOfUnreadComments]
  );

  return (
    <StyledModuleElementDrawer
      ref={ref}
      title={
        <div className="esg-module-element-drawer__title">
          <Icon name="sidebar-flip" />
          {title ?? 'Element Drawer'}
        </div>
      }
      placement="Right"
      className="esg-module-element-drawer"
    >
      <Tabs<ModuleElemetDrawerTab>
        value={activeTab}
        className="esg-module-element-drawer__tabs"
        tabs={drawerTabs}
        onChange={setActiveTab}
        changeOrientationOnResponsive={false}
      />
      <Divider />
      <DrawerSection
        elementId={id}
        updateNumberOfUnreadComments={(unreadComments) =>
          setNewNumberOfUnreadComments(unreadComments)
        }
      />
    </StyledModuleElementDrawer>
  );
};

export default forwardRef(ModuleElementDrawer);

import { IDataModule } from 'models';

const moduleModificationFunctions = {
  update: (
    module: IDataModule,
    modules: IDataModule[],
    newModule: IDataModule
  ) => {
    modules.push({ ...module, ...newModule });
    return modules;
  },
  delete: (_: IDataModule, modules: IDataModule[]) => modules,
};

/**
 * Modifies the modules, focusing on the provided module ID, including nested modules.
 *
 * @param modules - The array of modules to search through.
 * @param module - The module to modify.
 * @returns A new array of modules with the desired modification.
 */
const modifyModules =
  (
    modificationFn: (
      module: IDataModule,
      modules: IDataModule[],
      newModule: IDataModule
    ) => IDataModule[]
  ) =>
  (modules: IDataModule[], module: IDataModule): IDataModule[] => {
    return modules.reduce((acc: IDataModule[], currentModule) => {
      if (currentModule.id === module.id) {
        return modificationFn(currentModule, acc, module);
      }

      if (currentModule.elements) {
        const updatedElements = modifyModules(modificationFn)(
          currentModule.elements,
          module
        );
        acc.push({ ...currentModule, elements: updatedElements });
      } else acc.push(currentModule);

      return acc;
    }, []);
  };

export default {
  deleteModuleById: modifyModules(moduleModificationFunctions.delete),
  updateModuleById: modifyModules(moduleModificationFunctions.update),
};

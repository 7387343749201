import { theme } from '@faxi/web-component-library';
import { flex } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledModuleElementComments = styled.div`
  ${flex('column')};

  .esg-module-element-comments {
    &__comments {
      .spinner {
        align-self: center;
      }

      ${flex('column')};

      gap: ${theme.sizes.SIZE_32};
      margin-bottom: auto;
      flex: 1 1 auto;
      overflow-y: auto;
      overflow-x: hidden;
      height: 0px;
      scroll-padding-bottom: 30px;
      padding: 3px ${theme.sizes.SIZE_12};
    }
  }

  .esg-post-comment {
    margin-top: ${theme.sizes.SIZE_16};
  }
`;

export { StyledModuleElementComments };

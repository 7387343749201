import {
  ButtonProps,
  StatusElement,
  StatusElementStatus,
} from '@faxi/web-component-library';
import classNames from 'classnames';
import Icon from 'components/Icon';
import { CampaignSessionStatus, Session } from 'models';
import { FC, memo, MouseEvent, PropsWithChildren, useMemo } from 'react';

import ApprovalProgressBar from '../ApprovalProgressBar';
import ThreeDotMenu from '../ThreeDotMenu';
import { StyledSessionCard } from './SessionCard.styled';

export type SessionCardProps = PropsWithChildren<{
  session: Session;
  onSelect: () => void;
  onDelete?: (ev: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}>;

const SESSION_STATUS_MAPPER: Record<
  CampaignSessionStatus,
  StatusElementStatus
> = {
  [CampaignSessionStatus.Active]: 'active',
  [CampaignSessionStatus.Draft]: 'draft',
  [CampaignSessionStatus.Finished]: 'approved',
};

const SessionCard: FC<SessionCardProps> = ({
  session,
  onSelect,
  onDelete,
  className,
}) => {
  const defaultMenuItems = useMemo<ButtonProps[]>(
    () => [
      {
        children: 'Delete',
        icon: <Icon name="trash-can" className="color-secondary" />,
        iconPosition: 'left',
        variant: 'delete-ghost',
        onClick: (ev) => {
          ev.stopPropagation();
          onDelete?.(ev);
        },
      },
    ],
    [onDelete]
  );

  return (
    <StyledSessionCard
      onClick={onSelect}
      className={classNames('esg-session-card', 'hover-effect', className)}
    >
      {session.status === CampaignSessionStatus.Draft && (
        <ThreeDotMenu
          className="esg-session-card__menu"
          menuItems={defaultMenuItems}
        />
      )}

      <div
        className={classNames(
          'esg-session-card__column',
          'esg-session-card__column--name'
        )}
      >
        <strong className="esg-session-card__column__name">
          <Icon className="esg-session-card__column__name__icon" name="file" />
          <span>{session.name}</span>
          <StatusElement
            small
            status={SESSION_STATUS_MAPPER[session.status]}
            className="esg-session-card__column__name__draft"
          >
            {session.status}
          </StatusElement>
        </strong>
        <p className="esg-session-card__column__description">
          {session.description || '-'}
        </p>
      </div>

      <div
        className={classNames(
          'esg-session-card__column',
          'esg-session-card__column--status'
        )}
      >
        <ApprovalProgressBar
          title="Progress"
          status="In Progress"
          progress={session.progress}
        />
        {/* TODO */}
        <ApprovalProgressBar
          title="Approval"
          status="In Progress"
          progress={session.approval}
        />
      </div>
    </StyledSessionCard>
  );
};

export default memo(SessionCard);

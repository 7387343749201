import { FormulaDataModule, InlineModuleElement } from 'models';
import { FC, memo } from 'react';

import InlineEditable from '../../../../_molecules/InlineEditable';
import { StyledModuleContainer } from '../../styles/ModuleContainer.styled';

export type FormulaCanvasModuleProps = InlineModuleElement<FormulaDataModule>;

const FormulaCanvasModule: FC<FormulaCanvasModuleProps> = (props) => {
  return (
    <StyledModuleContainer className="esg-formula-canvas-module">
      <InlineEditable
        data-no-dnd="true"
        placeholderText={'Click to enter/edit text'}
        {...props}
      />
    </StyledModuleContainer>
  );
};

export default memo(FormulaCanvasModule);

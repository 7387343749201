import { Comment, PaginatedApiData } from 'models';
import useSWRInfinite, { SWRInfiniteConfiguration } from 'swr/infinite';

import { LIMIT } from '../../../constants';
import { API_ROUTES } from '../../routes';

export type InfinitePageData = { data: { nextPage?: any; items: any[] } };

export const defaultGetKeyFn =
  <T extends InfinitePageData>(baseUrl: string) =>
  (pageIndex: number, previousPageData: T | undefined): string | null => {
    if (pageIndex === 0) return baseUrl;

    if (!previousPageData || !previousPageData.data?.nextPage) return null;

    const encodedNextPage = encodeURIComponent(
      `${new URLSearchParams(previousPageData.data.nextPage)}`
    );

    return `${baseUrl}&exclusiveStartKey=${encodedNextPage}`;
  };

export const useInfiniteFetch = <T extends InfinitePageData>(
  baseUrl: string,
  config?: SWRInfiniteConfiguration,
  getKey = defaultGetKeyFn
) => {
  const { data, ...rest } = useSWRInfinite<T>(getKey(baseUrl), config);

  return {
    items: data?.flatMap((page) => page.data.items) || [],
    nextPage: data?.[data.length - 1]?.data.nextPage,
    ...rest,
  };
};

export const useGetComments = (
  sessionId: string,
  formId: string,
  elementId: string,
  config?: SWRInfiniteConfiguration
) => {
  const baseUrl = `${API_ROUTES.COMMENTS.SESSION_FORM_ELEMENT_COMMENTS(sessionId, formId, elementId)}?limit=${LIMIT}`;

  return useInfiniteFetch<PaginatedApiData<Comment>>(baseUrl, config);
};

import { elevate_l, theme } from '@faxi/web-component-library';
import { flex, fontSize } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledSectionPreview = styled.div`
  ${flex('column', 'center', 'flex-start')};
  ${elevate_l};

  gap: ${theme.sizes.SIZE_24};

  border-radius: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16}
    ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_8};

  padding: ${theme.sizes.SIZE_12} ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_24};
  ${theme.sizes.SIZE_24};

  border-left: ${theme.sizes.SIZE_4} solid var(--BLUE-00);
  width: 100%;

  .esg-section-preview {
    &__header {
      width: 100%;
      ${flex('row', 'flex-start', 'center')};
      border-bottom: 1px solid var(--GRAY-C1);
      padding-bottom: ${theme.sizes.SIZE_16};

      input {
        background-color: var(--WHITE-FF);
        border: solid 1px var(--GRAY-54);
        pointer-events: none;
      }

      svg {
        color: var(--BLACK-00);
        font-size: ${theme.sizes.SIZE_20};
      }
    }

    &__title {
      ${fontSize(theme.fontSizes.FONT_18)};
      margin-right: auto;
      font-weight: 600;
    }

    &__module {
      padding: ${theme.sizes.SIZE_16} ${theme.sizes.SIZE_8};
    }
  }
`;

export { StyledSectionPreview };

import { CAMPAIGN_ITEMS_ROUTES } from './CampaignItemsRoutes';
import { CAMPAIGN_SESSIONS_ROUTES } from './CampaignSessionsRoutes';
import { COMMENTS } from './Comments';
import { ORGANISATIONS } from './Organisations';
import { SESSIONS } from './Sessions';
import { USERS } from './Users';

export const API_ROUTES = {
  ORGANISATIONS,
  CAMPAIGN_ITEMS_ROUTES,
  CAMPAIGN_SESSIONS_ROUTES,
  USERS,
  SESSIONS,
  COMMENTS,
};

import { AxiosRequestConfig } from 'axios';
import { Campaign, TreeNodeElement } from 'models';
import { createContext, MutableRefObject } from 'react';
import { KeyedMutator } from 'swr';

export type CampaignProviderContextProps = {
  tree?: TreeNodeElement;
  isError: boolean;
  deletedCampaign: MutableRefObject<boolean>;
  mutateTree: KeyedMutator<{ data: Campaign }>;
  campaignItemsMutationRequest: (data: AxiosRequestConfig) => Promise<void>;
  isLoading: boolean;
};

export default createContext<CampaignProviderContextProps>({
  deletedCampaign: { current: false },
  isError: false,
  mutateTree: () => new Promise(() => {}),
  campaignItemsMutationRequest: () => new Promise(() => {}),
  isLoading: false,
});

import { Role } from '../../../../models';

export const MOCK_MENTIONS = [
  {
    id: '13a44802-5051-70cf-3b97-2810ea384590',
    name: 'Dusan Simijonovic',
    role: {
      id: '1',
      color: '#E68E1B',
      name: 'super admin',
    } as Role,
  },
  {
    id: 'c38498d2-40e1-70d2-2f28-64bda8b6e829',
    name: 'Chehin Toumi',
    role: {
      id: '1',
      color: '#E68E1B',
      name: 'super admin',
    } as Role,
  },
  {
    id: '3384c8d2-d0c1-70c5-93f4-d729848478fa',
    name: 'Ivan Videnovic',
    role: {
      id: '1',
      color: '#E68E1B',
      name: 'super admin',
    } as Role,
  },
  {
    id: '43748852-1021-70df-0e75-78b4309aaa51',
    name: 'Aleksandar Stojkovic',
    role: {
      id: '1',
      color: '#E68E1B',
      name: 'super admin',
    } as Role,
  },
];

import { ButtonProps } from '@faxi/web-component-library';
import Icon from 'components/Icon';
import { MouseEvent, useMemo } from 'react';
import { EventCardType } from 'types';

const useDefaultCardMenu = (
  onEdit?: EventCardType,
  onDelete?: EventCardType
) => {
  const menuItems = useMemo(
    () =>
      [
        ...(onEdit
          ? [
              {
                children: 'Edit',
                icon: <Icon name="pen" />,
                variant: 'ghost',
                iconPosition: 'left',
                onClick: (e: MouseEvent) => {
                  e.stopPropagation();
                  onEdit?.(e);
                },
              },
            ]
          : []),
        ...(onDelete
          ? [
              {
                children: 'Delete',
                icon: <Icon name="trash-can" className="color-secondary" />,
                iconPosition: 'left',
                variant: 'delete-ghost',
                onClick: (e: MouseEvent) => {
                  e.stopPropagation();
                  onDelete?.(e);
                },
              },
            ]
          : []),
      ] as ButtonProps[],
    [onDelete, onEdit]
  );

  return menuItems;
};

export default useDefaultCardMenu;

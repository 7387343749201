import { useCallback } from 'react';
import { useSWRConfig } from 'swr';

/**
 * This hook provides a function to delete cache keys based on a filter function.
 * It uses the SWR cache and mutate functions to manage the cache.
 *
 * Note: It should be used together with the "isValidating" property
 * from the "useSWR" hook to indicate ongoing revalidation.
 *
 * @see https://swr.vercel.app/docs/advanced/understanding.en-US#combining-with-isloading-and-isvalidating-for-better-ux
 */
const useSWRCache = () => {
  const { cache, mutate } = useSWRConfig();

  const mutateCacheKeys = useCallback(
    (filterKeyFn: (key: string) => boolean) => {
      const cacheCampaignKeys = Array.from(cache.keys()).filter(filterKeyFn);

      cacheCampaignKeys.forEach((key) => mutate(key));
    },
    [cache, mutate]
  );

  const clearCache = useCallback(() => {
    mutate(() => true);
  }, [mutate]);

  return { mutateCacheKeys, clearCache };
};

export default useSWRCache;

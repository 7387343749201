import { useCallback } from 'react';

import { AccessPermissions, PermissionSections } from '../models';
import { useRootProvider } from '../providers/Root';

/**
 *
 * Hook that returns function for checking user permissions with accessPermissions passed as parameters. Function returns boolean
 * @param section - represents the standalone entity that have its own permission set.For example (USER, CAMPAIGN,OGRAGANISATION,SESSION);
 */
const useUserPermissions = (section: PermissionSections) => {
  const { userTokenInformation: { permissions } = {} } = useRootProvider();

  const hasPermissions = useCallback(
    (accessPermissions: AccessPermissions[]) => {
      if (!permissions) return false;

      return accessPermissions.some((per) =>
        permissions[section].includes(per)
      );
    },
    [permissions, section]
  );

  return hasPermissions;
};

export default useUserPermissions;

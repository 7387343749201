import { theme } from '@faxi/web-component-library';
import styled from 'styled-components';

const StyledFileUploadModalField = styled.div`
  .button {
    font-size: ${theme.fontSizes.FONT_14};
  }
`;

export { StyledFileUploadModalField };

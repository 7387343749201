import { LinkParserWrapper } from '@faxi/web-component-library';
import { TextDataModule } from 'models';
import { FC } from 'react';

import { StyledTextPreview } from './TextPreview.styled';

export type TextPreviewProps = TextDataModule;

const TextPreview: FC<TextPreviewProps> = ({
  title,
  config: { textFormat } = { textFormat: 'body-text' },
}) => (
  <StyledTextPreview className={`esg-text-preview--${textFormat}`}>
    <LinkParserWrapper>{title}</LinkParserWrapper>
  </StyledTextPreview>
);

export default TextPreview;

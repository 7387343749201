import { focusStyles, theme } from '@faxi/web-component-library';
import { flex, fontSize, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledComment = styled.div<{ $isError: boolean; $isRead: boolean }>`
  ${flex('column')};

  gap: ${theme.sizes.SIZE_24};
  ${focusStyles}

  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }

  &.esg-comment--error {
    animation: horizontal-shaking 0.35s 0s;

    .esg-comment {
      &__author {
        .esg-user-mention-option {
          &__name-container {
            img {
              filter: grayscale(80%);
            }

            > span {
              color: ${({ $isError }) =>
                `var(${$isError ? '--GRAY-C1' : '--BLACK-00'})`};
            }
          }
          .esg-basic-tag {
            background-color: ${({ $isError }) =>
              `var(${$isError ? '--GRAY-C1' : '--BLACK-00'})`};
          }
        }
        > p {
          ${fontSize(theme.fontSizes.FONT_12)};
          ${flex('row', 'flex-start', 'center')};
          gap: ${theme.sizes.SIZE_6};

          color: var(--RED-D5);
        }

        > button {
          ${fontSize(theme.fontSizes.FONT_14)};
          transition: transform 0.2s ease-in-out;
          border-radius: ${theme.sizes.SIZE_32};
          padding: ${theme.sizes.SIZE_8} ${theme.sizes.SIZE_16};
          min-height: ${theme.sizes.SIZE_40};

          .esg-loading {
            margin: auto;
          }

          &::after {
            border: 1px solid var(--GRAY-C1);
          }
        }
      }
    }
  }

  .esg-comment {
    &__author {
      ${flex('row', 'flex-start', 'center')};

      gap: ${theme.sizes.SIZE_6};
      position: relative;

      &::before {
        ${size('6px')};
        content: '';
        display: ${({ $isRead }) => ($isRead ? 'none' : 'block')};
        position: absolute;
        top: 50%;
        left: -${theme.sizes.SIZE_8};
        transform: translate(-50%, -50%);
        background-color: var(--BLUE-00);
        border-radius: 50%;
      }

      > span {
        ${fontSize(theme.fontSizes.FONT_12)};

        color: var(--GRAY-70);
      }

      .esg-user-mention-option {
        gap: ${theme.sizes.SIZE_6};

        &__name-container {
          img {
            ${size(theme.sizes.SIZE_24)};
          }
          > span {
            ${fontSize(theme.fontSizes.FONT_14)};
            font-weight: 600;
          }
        }
      }
    }

    &__message {
      ${fontSize(theme.fontSizes.FONT_14)};

      padding-left: 34px;
      word-break: break-word;
      color: ${({ $isError }) =>
        `var(${$isError ? '--GRAY-C1' : '--BLACK-00'})`};

      > span {
        color: ${({ $isError }) =>
          `var(${$isError ? '--GRAY-C1' : '--BLUE-00'})`};
      }
    }
  }
`;

export { StyledComment };

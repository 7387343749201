import {
  Button,
  Divider,
  Heading,
  Icon,
  OverlayRef,
  useUtilities,
} from '@faxi/web-component-library';
import { Form } from '@faxi/web-form';
import classNames from 'classnames';
import {
  AssignContributors,
  CollapsibleSidePanel,
  NotificationBadgeWrapper,
} from 'components';
import ModuleElement from 'components/_organisms/BuilderCanvas/components/ModuleElement';
import { BlockUI, Each } from 'helpers';
import { DataModuleEnum, IDataModule, SessionForm } from 'models';
import { FC, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';

import useMutation from '../../../../api/hooks/useMutation';
import { API_ROUTES } from '../../../../api/routes';
import ModuleElementDrawer from '../../../../routes/subrouters/Sessions/components/ModuleElementDrawer';
import { generateInitialFormModulesData } from '../../../../utils';
import { StyledSessionReportsReport } from './SessionReportsReport.styled';

const filterEmptySectionModules = (
  elements: IDataModule<DataModuleEnum>[] = []
): IDataModule<DataModuleEnum>[] =>
  elements.filter(({ type, elements }) => {
    if (type === DataModuleEnum.SECTION) {
      const filteredNestedElements = elements
        ? filterEmptySectionModules(elements)
        : [];

      return filteredNestedElements.length > 0;
    }

    return true;
  });

const SessionReportsReport: FC = () => {
  const [activeModule, setActiveModule] = useState<IDataModule>();
  const { companyId, formId } = useParams();
  const [searchParams] = useSearchParams();

  const moduleDrawerRef = useRef<OverlayRef>(null);

  // const { sessionTreeWithOrganisationRoot, isLoading, error } =
  //   useGetSessionTree(params?.sessionId as string);

  const apiEndpoint =
    companyId &&
    formId &&
    API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.ORGANISATION_SESSION_FORM(
      companyId,
      formId
    );

  const {
    data: { data: form } = {},
    isLoading,
    isValidating,
    error,
  } = useSWR<{ data: SessionForm & { elements: IDataModule[] } }>(apiEndpoint);

  // TODO

  // const currentEntry = useMemo(() => {
  //   return findCurrentSessionEntry(
  //     sessionTreeWithOrganisationRoot as CampaignSessionTreeNode,
  //     params?.[MAP_ID['subsubtopic']] as string
  //   );
  // }, [params, sessionTreeWithOrganisationRoot]);

  // const subSessionId = useMemo(
  //   () =>
  //     sessionTreeWithOrganisationRoot &&
  //     findNode(
  //       params[MAP_ID['company']] as string,
  //       Array(sessionTreeWithOrganisationRoot) as CampaignSessionTreeNode[]
  //     )?.companySessionId,
  //   [params, sessionTreeWithOrganisationRoot]
  // );

  // TODO
  // const {
  //   modules,
  //   progress,
  //   formData,
  //   formStatus,
  //   formApproval,
  //   loadingStatus,
  //   updateFormStatus,
  // } = useFormModules({
  //   asAdmin: true,
  //   sessionId: companyId,
  //   collectionId: formId,
  // });

  const modules = useMemo(
    () => filterEmptySectionModules(form?.elements) || [],
    [form?.elements]
  );

  const initialFormData = useMemo(
    () => generateInitialFormModulesData(modules),
    [modules]
  );

  const { showSnackBar } = useUtilities();

  const { trigger: submitSessionForm } = useMutation(
    apiEndpoint,
    {
      onSuccess: () =>
        showSnackBar({
          text: 'Form successfully submitted!',
          variant: 'success',
          actionButtonText: 'Dismiss',
        }),
    },
    true
  );

  return (
    <StyledSessionReportsReport
      className={classNames('esg-session-reports-report', {
        'esg-session-reports-report--error': false,
      })}
    >
      <BlockUI loading={isLoading || isValidating} error={error}>
        <div className="esg-session-reports-report__content">
          <div className="esg-session-reports-report__content__header">
            <Heading level="1">{searchParams.get('name')}</Heading>
          </div>

          {/* TODO */}
          {/* {currentEntry?.description && (
            <p className="esg-session-reports-report__content__subheader">
              {currentEntry?.description}
            </p>
          )} */}

          <Form
            className="esg-session-reports-report__content__elements"
            initialData={initialFormData}
            onSubmit={async (data) => {
              if (!form) return;

              const finalData = {
                elements: Object.entries(data).map(([expandedId, value]) => ({
                  id: expandedId.split('+')[0],
                  type: expandedId.split('+')[1],
                  value,
                })),
              };

              await submitSessionForm({
                method: 'POST',
                data: finalData,
              });

              mutate(API_ROUTES.CAMPAIGN_SESSIONS_ROUTES.BASE());
            }}
          >
            <Each
              of={modules}
              render={(module) => (
                <div className="esg-session-reports-report__content__elements__container">
                  <ModuleElement
                    modulesType="preview-module"
                    type={module.type}
                    module={module}
                    key={module.id}
                  />
                  <Button
                    className="esg-session-reports-report__content__elements__container__comments-button"
                    //TODO: add props when BE for unread comments is ready
                    icon={
                      <NotificationBadgeWrapper
                        hasNotification={!!module.numberOfUnreadComments}
                      >
                        <Icon name="sidebar-flip" />
                      </NotificationBadgeWrapper>
                    }
                    variant="ghost"
                    onClick={() => {
                      setActiveModule(module);
                      moduleDrawerRef?.current?.open();
                    }}
                  />
                </div>
              )}
            />
            <ModuleElementDrawer ref={moduleDrawerRef} module={activeModule} />
            <div className="esg-session-reports-report__content__elements__actions">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </div>

        <CollapsibleSidePanel className="esg-session-reports-report__contributors">
          <AssignContributors />
          <Divider />
          {/* <ReportStatus
            asAdmin
            progress={progress}
            loading={loadingStatus}
            formStatus={formStatus}
            formApproval={formApproval}
            onStatusChange={updateFormStatus}
          /> */}
        </CollapsibleSidePanel>
      </BlockUI>
    </StyledSessionReportsReport>
  );
};

export default SessionReportsReport;

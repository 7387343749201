import { APP_URI } from 'config';
import {
  RunSession,
  SessionDashboard,
  SessionDashboardCompany,
  SessionPage,
  SessionReportsEntry,
  SessionReportsReport,
  Sessions,
} from 'pages';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import SessionActivity from '../../../pages/Sessions/components/SessionActivity';
import SessionContributors from '../../../pages/Sessions/components/SessionContributors';
import SessionDetails from '../../../pages/Sessions/components/SessionDetails';

export default [
  { path: '', element: <Sessions /> },
  {
    path: APP_URI.SESSIONS_SESSION,
    element: <SessionPage />,
    children: [
      {
        path: APP_URI.SESSION_SESSION_RUN,
        element: <RunSession />,
      },
      {
        path: APP_URI.SESSION_SESSION_DETAILS,
        element: <SessionDetails />,
        children: [
          {
            path: '',
            element: (
              <Navigate to={APP_URI.SESSION_SESSION_DETAILS_DASHBOARD} />
            ),
          },
          {
            path: APP_URI.SESSION_SESSION_DETAILS_DASHBOARD,
            children: [
              {
                path: '',
                element: <SessionDashboard />,
              },
              {
                path: APP_URI.SESSION_SESSION_DETAILS_DASHBOARD_COMPANY,
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <SessionDashboardCompany />,
                  },
                  {
                    path: APP_URI.SESSION_SESSION_DETAILS_DASHBOARD_COMPANY_FORM,
                    element: <SessionReportsReport />,
                  },
                ],
              },
            ],
          },
          {
            path: APP_URI.SESSION_SESSION_DETAILS_ACTIVITY,
            element: <SessionActivity />,
          },
          {
            path: APP_URI.SESSION_SESSION_DETAILS_MEMBERS,
            element: <SessionContributors />,
          },
          {
            path: APP_URI.SESSION_SESSION_DETAILS_REPORT,
            children: [
              {
                path: '',
                element: <SessionReportsEntry name="organisation" />,
              },
              {
                path: APP_URI.SESSION_REPORT_COMPANY,
                element: <SessionReportsEntry name="company" />,
              },
              {
                path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC,
                element: <SessionReportsEntry name="topic" />,
              },
              {
                path: APP_URI.SESSION_REPORT_COMPANY_TOPICS_TOPIC_SUBTOPICS_SUBTOPIC,
                element: <SessionReportsEntry name="subtopic" />,
              },
            ],
          },
        ],
      },
    ],
  },
  {},
] as RouteObject[];

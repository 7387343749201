import isEmpty from 'lodash.isempty';

import {
  DataModuleEnum,
  ModuleConfig,
  ModuleConfigType,
  ModuleWithConfig,
} from '../models';
import { generateModuleFieldName } from './generateModuleFieldName';

export const generateInitialFormModulesData = (
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
) => {
  /**
   * Generate a new array of all modules and their conditional elements (modules)
   */
  const generateArrayOfAllModulesWithConfigs = (
    modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  ): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
    modules
      .filter(Boolean)
      .reduce((acc: ModuleConfig<ModuleConfigType, DataModuleEnum>[], curr) => {
        if (curr.elements && curr.type === 'section') {
          return [
            ...acc,
            ...generateArrayOfAllModulesWithConfigs(curr.elements),
          ];
        }

        if (!isEmpty(curr.conditionalElements)) {
          return [...acc, curr, ...Object.values(curr.conditionalElements)];
        }

        return [...acc, curr];
      }, []);

  const allModules = generateArrayOfAllModulesWithConfigs(modules);

  return allModules.reduce((acc: Record<string, unknown>, module) => {
    const {
      type,
      id,
      config = {},
      value,
    } = module as ModuleWithConfig & { value: unknown };

    switch (type) {
      case DataModuleEnum.CHOICE:
        acc[generateModuleFieldName(id, DataModuleEnum.CHOICE)] =
          value || config.defaultValue || '';
        break;
      case DataModuleEnum.CHECKLIST:
        acc[generateModuleFieldName(id, DataModuleEnum.CHECKLIST)] =
          value ||
          (config.defaultValues || []).map(
            ({ value }: { value: string }) => value
          );
        break;
      case DataModuleEnum.INPUT:
        acc[generateModuleFieldName(id, DataModuleEnum.INPUT)] =
          value || config.defaultValue;
        break;
      case DataModuleEnum.SWITCH:
        acc[generateModuleFieldName(id, DataModuleEnum.SWITCH)] =
          value || config.defaultValue;
        break;
      case DataModuleEnum.DROPDOWN:
        acc[generateModuleFieldName(id, DataModuleEnum.DROPDOWN)] =
          value || config.defaultValue;
        break;
      case DataModuleEnum.INTEGRATION:
        acc[generateModuleFieldName(id, DataModuleEnum.INTEGRATION)] =
          value || config.defaultValue;
        break;
      case DataModuleEnum.FORMULA:
        acc[generateModuleFieldName(id, DataModuleEnum.FORMULA)] =
          value || config.formula;
        break;
      default:
        break;
    }
    return acc;
  }, {});
};

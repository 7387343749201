import { Coordinates } from '@dnd-kit/core/dist/types';
import classNames from 'classnames';
import { CommentType, UserMention } from 'models';
import {
  FormEvent,
  forwardRef,
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  calculateElementPositionOnScreen,
  compareNameAndMention,
  findElementInSelection,
  getCursorPosition,
  getMentionInformation,
  parseContentEditableValue,
  replaceSpanWithoutId,
  setCursorPositionOnEndOfMention,
  setPosition,
  spanWithId,
  spanWithoutId,
  textElement,
} from 'utils';

import UserMentionOption from '../UserMentionOption';
import {
  MentionsDropdownContainer,
  StyledContentEditableWithMentions,
  StyledOverlay,
} from './ContentEditableWithMentions.styled';

//TODO: Change type when connecting with api
export type ContentEditableWithMentionsProps = PropsWithChildren<{
  mentions: UserMention[];
  onContentEditableChange: (value: Array<CommentType>) => void;
}>;

export type ContentEditableRef = {
  clearValue?: () => void;
};

const ContentEditableWithMentions: React.ForwardRefRenderFunction<
  ContentEditableRef,
  ContentEditableWithMentionsProps
> = (props, ref) => {
  const { mentions, onContentEditableChange } = props;

  const [dropdownPosition, setDropdownPosition] = useState<Coordinates>();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [mentionsFilter, setMentionsFilter] = useState<string>('');
  const [currentMentionIndex, setCurrentMentionIndex] = useState<number>(0);
  const [listOfEstablishedMentions, setListOfEstablishedMentions] = useState<
    UserMention[]
  >([]);

  const filterSpan = useRef<HTMLSpanElement>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentEditableRef = useRef<HTMLDivElement>(null);

  const filteredMensions = useMemo(
    () =>
      mentions.filter((user) =>
        compareNameAndMention(user.name, `@${mentionsFilter}`)
      ),
    [mentions, mentionsFilter]
  );

  const clearContentEditableState = () => {
    setDropdownPosition(undefined);
    setShowDropdown(false);
    setMentionsFilter('');
    setCurrentMentionIndex(0);
    setListOfEstablishedMentions([]);
  };

  const clearValue = useCallback(() => {
    clearContentEditableState();
    if (contentEditableRef.current) {
      contentEditableRef.current.innerHTML = '';
      contentEditableRef.current.blur();
    }

    filterSpan.current = undefined;
  }, []);

  useImperativeHandle(ref, () => ({ clearValue }), [clearValue]);

  const handleOnSelectMention = useCallback(
    (mention: UserMention) => {
      if (!filterSpan.current) return;

      // Replace mentions with styled HTML
      filterSpan.current.innerHTML = `@${mention.name}`;
      filterSpan.current.dataset.id = mention.id;

      setListOfEstablishedMentions((prev) =>
        prev.every((el) => el.id !== mention.id) ? [...prev, mention] : prev
      );

      contentEditableRef.current?.focus();

      setCursorPositionOnEndOfMention(filterSpan.current);

      const paredValue = parseContentEditableValue(
        contentEditableRef.current?.childNodes!,
        mentions
      );

      onContentEditableChange(paredValue);

      setMentionsFilter('');
      setDropdownPosition(undefined);
      setShowDropdown(false);
      filterSpan.current = undefined;
    },
    [mentions, onContentEditableChange]
  );

  const onInput = (e: FormEvent<HTMLDivElement>) => {
    if (e.currentTarget.innerHTML === '<br>') {
      e.currentTarget.innerHTML = '';
      clearContentEditableState();
      onContentEditableChange([]);
      return;
    }

    // Identify the current element where the cursor resides
    const element = findElementInSelection();

    const pos = getCursorPosition(e.currentTarget);

    const isFontNode = element.nodeName === 'FONT';
    const isFontParentNode = element.parentNode?.nodeName === 'FONT';

    //reset font element if browser inject
    if (isFontNode || isFontParentNode) {
      const textNode = document.createTextNode(element.textContent ?? '');
      e.currentTarget.replaceChild(
        textNode,
        isFontParentNode ? element.parentNode : element
      );
    }

    // CASE 1: Cursor is inside a SPAN WITH ID (Established mention)
    if (element?.classList.contains('mention') && element.dataset.id) {
      replaceSpanWithoutId(e.currentTarget);
      spanWithId(
        element,
        listOfEstablishedMentions,
        contentEditableRef.current!
      );
    }

    //CASE 2: Text node
    else if (element?.id === 'content-editable-with-mentions') {
      replaceSpanWithoutId(e.currentTarget);
      const selection = window.getSelection();
      const currentNode = selection?.anchorNode;

      textElement(currentNode!, element, mentions);
    }

    // CASE 3: Cursor is inside a SPAN WITHOUT ID (Temporary mention span)
    else if (element?.classList.contains('mention') && !element.dataset.id) {
      const matchingMention = spanWithoutId(
        element,
        mentions,
        contentEditableRef.current!
      );

      if (matchingMention) {
        setListOfEstablishedMentions((prev) =>
          prev.every((el) => el.id !== matchingMention.id)
            ? [...prev, matchingMention]
            : prev
        );
      }
    }

    setPosition(e.currentTarget, pos);

    const { showDropdown, filterText, spanEl } = getMentionInformation() || {};

    const parsedValue = parseContentEditableValue(
      e.currentTarget.childNodes,
      mentions
    );

    onContentEditableChange(parsedValue);

    filterSpan.current = spanEl;
    setDropdownPosition(dropdownPosition);
    setShowDropdown(showDropdown || false);
    setMentionsFilter(filterText || '');
  };

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>, mentions: Array<any>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }

      if (
        e.key === 'Enter' &&
        currentMentionIndex >= 0 &&
        dropdownPosition &&
        mentions[currentMentionIndex]
      ) {
        handleOnSelectMention(mentions[currentMentionIndex]);
      }

      if (e.key === 'ArrowDown' && dropdownPosition) {
        e.preventDefault();
        setCurrentMentionIndex((prevIndex) =>
          Math.min(prevIndex + 1, mentions.length - 1)
        );
      }

      if (e.key === 'ArrowUp' && dropdownPosition) {
        e.preventDefault();
        setCurrentMentionIndex((prevIndex) => Math.max(0, prevIndex - 1));
      }
    },
    [currentMentionIndex, dropdownPosition, handleOnSelectMention]
  );

  useEffect(() => {
    if (showDropdown && filteredMensions) {
      const dropdownPosition = calculateElementPositionOnScreen(
        filterSpan.current!,
        dropdownRef.current!
      );
      setDropdownPosition(dropdownPosition);
      setCurrentMentionIndex(0);
    } else {
      setDropdownPosition(undefined);
      setCurrentMentionIndex(-1);
    }
  }, [showDropdown, filteredMensions]);

  return (
    <StyledContentEditableWithMentions
      className="esg-content-editable-with-mentions"
      tabIndex={0}
    >
      <div
        id="content-editable-with-mentions"
        ref={contentEditableRef}
        className="esg-content-editable-with-mentions__editable-container"
        contentEditable
        onInput={onInput}
        aria-placeholder="Message"
        onKeyDown={(e) => handleKeyDown(e, filteredMensions)}
        tabIndex={-1}
      />
      {
        <MentionsDropdownContainer
          ref={dropdownRef}
          $x={dropdownPosition?.x}
          $y={dropdownPosition?.y}
          $show={Boolean(dropdownPosition && showDropdown)}
        >
          <StyledOverlay onClick={() => setShowDropdown(false)} />
          {filteredMensions.map((el, ind) => (
            <UserMentionOption
              key={el.id}
              name={el.name}
              role={el.role}
              className={classNames({ active: currentMentionIndex === ind })}
              onClick={(e) => {
                e.stopPropagation();
                handleOnSelectMention(el);
              }}
              onKeyDown={(e) => handleKeyDown(e, filteredMensions)}
            />
          ))}
        </MentionsDropdownContainer>
      }
    </StyledContentEditableWithMentions>
  );
};

export default forwardRef(ContentEditableWithMentions);

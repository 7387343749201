import { FC } from 'react';

import { DataModuleElementDrawerComponentProps } from '../../../../../models';
import { StyledModuleElementActivity } from './ModuleElementActivity.styled';

//TODO
const ModuleElementActivity: FC<DataModuleElementDrawerComponentProps> = () => {
  return (
    <StyledModuleElementActivity className="esg-module-element-activity">
      hello from ModuleElementActivity!
    </StyledModuleElementActivity>
  );
};

export default ModuleElementActivity;

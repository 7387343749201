import env from 'env';

const apiVersion = env.VITE_API_VERSION;

const baseUrl = `/campaign-sessions/${apiVersion}`;

export const CAMPAIGN_SESSIONS_ROUTES = {
  BASE: (search?: string) =>
    `${baseUrl}${search ? '?searchString=' + search : ''}`,

  SESSION: (sessionId: string) => `${baseUrl}/${sessionId}`,

  CAMPAIGN_SESSION_FORM: (sessionId: string, formId: string) =>
    `${baseUrl}/${sessionId}/forms/${formId}`,

  CAMPAIGN_SESSION_RUN: (id: string) => `${baseUrl}/${id}/run`,

  CAMPAIGN_SESSION_SUBMIT_FORM: (organisationId: string) =>
    `${baseUrl}/${organisationId}/submit-form`,

  CAMPAIGN_SESSION_INCLUDE: (sessionId: string, organisationId: string) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/include`,

  CAMPAIGN_SESSION_EXCLUDE: (sessionId: string, organisationId: string) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/exclude`,

  CAMPAIGN_SESSION_ORGANISATION_DATA_COLLECTION_ELEMENTS: (
    sessionId: string,
    organisationId: string
  ) =>
    `${baseUrl}/${sessionId}/organisations/${organisationId}/data-collection-elements`,
  //TODO:Change this when api is finished.
  CAMPAIGN_SESSIONS_FILES_UPLOAD: (sessionId: string) =>
    `${baseUrl}/upload-url`,

  ORGANISATION_SESSIONS: (sessionId: string) =>
    `${baseUrl}/${sessionId}/organisation-sessions`,

  ORGANISATION_SESSION: (organisationSessionId: string) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}`,

  ORGANISATION_SESSION_FORM: (
    organisationSessionId: string,
    dataCollectionFormId: string
  ) =>
    `${baseUrl}/organisation-sessions/${organisationSessionId}/forms/${dataCollectionFormId}`,
};

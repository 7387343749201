import { Form, FormField, validators } from '@faxi/web-form';
import { API_ROUTES } from 'api/routes';
import { FormFooter, InputField } from 'components';
import { APP_URI } from 'config';
import { useCallbackAsync, useSWRCache, useValidations } from 'hooks';
import { AuthResponse } from 'models';
import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useMutation from '../../../../../api/hooks/useMutation';
import PasswordField from '../../../../../components/_fields/PasswordField';
import authBus, { AUTH_BUS_EVENTS } from '../../../../../modules/authBus';
import authStorageService from '../../../../../services/authStorageService';

type LoginFormType = {
  username: string;
  password: string;
};

const Login: FC = () => {
  const navigate = useNavigate();
  const { clearCache } = useSWRCache();

  const { trigger } = useMutation<AuthResponse>(API_ROUTES.USERS.LOGIN, {
    onSuccess: ({ data }) => {
      clearCache();

      if (authStorageService.isNewUser(data)) {
        authStorageService.loginNewUser(data);
        navigate('/setup-password');
      } else {
        authStorageService.loginUser(data);
        navigate('/campaigns');
        authBus.broadcastEvent(AUTH_BUS_EVENTS.UPDATE_USER, data.idToken);
      }
    },
  });

  const { validations } = useValidations();

  const [handleSubmit] = useCallbackAsync({
    showSpinner: true,
    callback: async (data: LoginFormType) => {
      await trigger({ method: 'POST', data });
    },
  });

  return (
    <Form onSubmit={handleSubmit} className="">
      <FormField
        name="username"
        type="text"
        component={InputField}
        autoComplete="off"
        placeholder="Email"
        validate={validations.email}
      />

      <FormField
        name="password"
        component={PasswordField}
        placeholder="Password"
        validate={validators.general.required('Password is required')}
      />

      <Link
        className="forgot-password"
        to={`../${APP_URI.AUTH_FORGOT_PASSWORD}`}
      >
        Forgot password?
      </Link>
      <FormFooter submitLabel="Login" />

      <p className="auth-text">
        Do not have an account?
        <Link to={`../${APP_URI.AUTH_SIGN_UP}`}> Sign Up</Link>
      </p>
    </Form>
  );
};

export default Login;

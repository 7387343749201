import { useUtilities } from '@faxi/web-component-library';
import api from 'api';
import EntityFormModal, {
  EntityFormModalProps,
} from 'components/_forms/EntityFormModal';
import { Organisation } from 'models';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { API_ROUTES } from '../../../../../api/routes';
import { useOrganisationProvider } from '../../../../../providers/Organisation';
import { useRootProvider } from '../../../../../providers/Root';

type CompanyFormType = Pick<Organisation, 'name' | 'description'>;

function CompanyModal<T extends Record<string, any>>(
  props: Omit<EntityFormModalProps<T>, 'onSubmit'>
) {
  const { initialData: company, onClose, ...rest } = props;

  const { mutateOrganisations } = useOrganisationProvider();

  const { showSnackBar } = useUtilities();

  const {
    user: {
      organisation: { id: rootOrganisationId },
    },
  } = useRootProvider();

  const { organisationId } = useParams<{
    organisationId: string;
  }>();

  const showSnackMessage = useCallback(
    (data: Organisation) => {
      showSnackBar({
        text: `${data.name} ${company ? 'updated' : 'created'}.`,
        variant: 'success',
        actionButtonText: 'Dismiss',
      });
    },
    [company, showSnackBar]
  );

  const onSuccess = useCallback(
    (response: { data: Organisation }) => {
      mutateOrganisations();
      showSnackMessage(response.data);
      onClose?.();
    },
    [mutateOrganisations, showSnackMessage, onClose]
  );

  const { trigger: updateCompany, isMutating: updateMutating } =
    api.useMutation(API_ROUTES.ORGANISATIONS.ORGANISATION(company?.id), {
      onSuccess,
    });

  const { trigger: createCompany, isMutating: createMutating } =
    api.useMutation<{ data: Organisation }>(
      API_ROUTES.ORGANISATIONS.ORGANISATION(
        organisationId || rootOrganisationId
      ),
      { onSuccess }
    );

  const loading = useMemo(
    () => updateMutating || createMutating,
    [createMutating, updateMutating]
  );

  const handleSubmit = useCallback(
    async (data: CompanyFormType) => {
      const { name, description } = data;

      if (company) {
        await updateCompany({ method: 'PATCH', data: { description } });
      } else {
        await createCompany({ method: 'POST', data: { name, description } });
      }
    },
    [company, updateCompany, createCompany]
  );

  return (
    <EntityFormModal
      className="esg-company-modal"
      loading={loading}
      initialData={
        company
          ? {
              name: company.name,
              description: company.description,
            }
          : undefined
      }
      title={company ? `Edit ${company.name} organisation` : 'Add organisation'}
      onSubmit={handleSubmit}
      onClose={onClose}
      {...rest}
    />
  );
}

export default CompanyModal;

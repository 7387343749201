import { theme } from '@faxi/web-component-library';
import { flex, fontSize, position } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledTreeNode = styled.div<{ $level: number }>`
  ${flex('column', 'flex-start', 'baseline')};
  ${fontSize(theme.fontSizes.FONT_14)};

  width: 100%;
  padding-left: ${({ $level }) => ($level === 1 ? 0 : 20)}px;

  .wcl-checkbox {
    transform: scale(0.7);
  }

  .esg-tree-node-component {
    &__base-node {
      ${flex('row', 'flex-start', 'center')};

      border-radius: 6px;
      width: 100%;
      border-radius: ${theme.sizes.SIZE_8};

      .wcl-checkbox {
        padding-left: ${theme.sizes.SIZE_8};
      }

      &--active {
        background-color: var(--GRAY-EF);
        font-weight: 600;
      }
      &:hover {
        background-color: var(--GRAY-EF);
      }
    }

    &__sub-folders {
      width: 100%;
      overflow: hidden;
    }

    &__icon {
      ${flex('row', 'flex-start', 'center')};
      ${fontSize(theme.fontSizes.FONT_14, theme.sizes.SIZE_24)};

      gap: ${theme.sizes.SIZE_8};
      position: relative;
      cursor: pointer;
      width: 100%;
      border-radius: 6px;
      padding: ${theme.sizes.SIZE_8};
      padding-right: ${theme.sizes.SIZE_40};

      &--leaf {
        cursor: pointer;
      }

      i {
        padding: ${theme.sizes.SIZE_4};
      }

      &__menu {
        ${position('absolute', 'top 50% right 0')};
        transform: translate3d(0, -50%, 0);
      }

      &:hover {
        .esg-tree-node-component__icon__action {
          visibility: visible;
        }
      }

      &__action {
        background-color: transparent;
        color: var(--BLACK-00);

        border-radius: ${theme.sizes.SIZE_32};
        padding: ${theme.sizes.SIZE_6} ${theme.sizes.SIZE_16};
        min-height: ${theme.sizes.SIZE_32};
        margin-left: auto;

        ${fontSize(theme.fontSizes.FONT_14)}

        &--show-on-hover {
          visibility: hidden;
        }

        &::after {
          border-color: var(--GRAY-C1);
        }

        &:hover {
          background-color: var(--WHITE-FF);
        }
      }

      &--active {
        background-color: var(--SHADE_1_7);
        font-weight: 600;
      }

      &--disabled {
        background-color: #eff0f0;
        color: gray;
        cursor: not-allowed;
      }
    }
  }
`;

export { StyledTreeNode };

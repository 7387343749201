import { Button, ModalProps, ModalRef } from '@faxi/web-component-library';
import classNames from 'classnames';
import { FolderNavHeader, SectionCard, ViewGuard } from 'components';
import { EntityFormModal } from 'components/_forms';
import { EntityFormFieldProps } from 'components/_forms/EntityFormModal/EntityFormModal.component';
import Icon from 'components/Icon';
import { APP_URI } from 'config';
import { Campaign, CampaignItem, PermissionSections } from 'models';
import { FC, useCallback, useMemo, useRef } from 'react';
import { To, useLocation, useNavigate } from 'react-router-dom';

import { BlockUI } from '../../../../helpers';
import { useDeleteCampaignItem, useUserPermissions } from '../../../../hooks';
import { useCampaignProvider } from '../../../../providers/Campaign';
import { useCampaignItem } from '../../context/CampaignItem';
import {
  generateCampaignItemCrumbs,
  mappedSectionTypeToIcon,
  mappedSectionTypeToStatus,
  mappedSectionTypeToTitle,
} from '../../utils';
import { StyledCampaignEntity } from './CampaignEntity.styled';

type EntityType = 'campaign' | 'topic' | 'subtopic' | 'subsubtopic';

export type CampaignEntityProps = {
  defaultItems?: Campaign[];
  campaignItem?: CampaignItem;
  entityType: EntityType;
  isLoading?: boolean;
  modalProps?: ModalProps & EntityFormFieldProps & { chooseType?: boolean };
  onSubmit: (data: Record<string, any>) => Promise<void>;
  navigateTo?: (v: string) => To;
};

const mapEntityTypeToRegularText: Record<EntityType, string> = {
  'campaign': 'campaign',
  'topic': 'topic',
  'subtopic': 'sub topic',
  'subsubtopic': 'sub sub topic',
};

const CampaignEntity: FC<CampaignEntityProps> = (
  props: CampaignEntityProps
) => {
  const {
    defaultItems,
    modalProps,
    campaignItem,
    isLoading,
    entityType,
    navigateTo,
    onSubmit: pOnSubmit,
  } = props;

  const hasPermission = useUserPermissions(PermissionSections.CAMPAIGN);

  const {
    path: { names = [], ids = [] } = {},
    name = '',
    children: campaignItemsChildren,
    id = '',
  } = campaignItem || {};

  const { pathname } = useLocation();
  const {
    campaignItem: activeCampaignItem,
    setCampaignItem,
    removeActiveCampaignItem,
  } = useCampaignItem();

  const { campaignItemsMutationRequest } = useCampaignProvider();

  const onDeleteSubmit = useCallback(
    async (id: string) => {
      await campaignItemsMutationRequest({ method: 'DELETE', params: { id } });
    },
    [campaignItemsMutationRequest]
  );

  const { onDelete } = useDeleteCampaignItem(onDeleteSubmit);

  const modalRef = useRef<ModalRef>(null);

  const navigate = useNavigate();

  const crumbs = useMemo(
    () => generateCampaignItemCrumbs(names, ids, id, name),
    [id, ids, name, names]
  );

  const openEditModal = useCallback(
    (item: CampaignItem) => {
      setCampaignItem(item);
      modalRef?.current?.open();
    },
    [setCampaignItem]
  );

  const items = defaultItems ?? campaignItemsChildren;

  const buttonNoMargin = useMemo(
    () => pathname === APP_URI.CAMPAIGNS,
    [pathname]
  );

  const entityTitle = useMemo(
    () => `Create a new ${mapEntityTypeToRegularText[entityType]}`,
    [entityType]
  );

  const onSubmit = useCallback(
    async (data: Record<string, any>) => {
      await pOnSubmit(data);
    },
    [pOnSubmit]
  );

  return (
    <BlockUI loading={isLoading}>
      <StyledCampaignEntity className="esg-campaign-entity">
        {campaignItem && (
          <FolderNavHeader
            crumbs={crumbs}
            title={name}
            description={campaignItem.description}
            updateDescription={() => openEditModal(campaignItem)}
            icon="clipboard-list"
            hasActions={hasPermission(['update'])}
          />
        )}
        <ViewGuard
          section={PermissionSections.CAMPAIGN}
          permissions={['create']}
        >
          <Button
            icon={<Icon name="plus" />}
            variant="outline"
            onClick={() => modalRef?.current?.open()}
            className={classNames('esg-campaign-entity__add', {
              'esg-campaign-entity__add--no-margin': buttonNoMargin,
            })}
          >
            {entityTitle}
          </Button>
        </ViewGuard>
        <EntityFormModal
          ref={modalRef}
          title={
            !activeCampaignItem
              ? entityTitle
              : `Edit ${activeCampaignItem.name}`
          }
          onSubmit={onSubmit}
          initialData={activeCampaignItem}
          onClose={removeActiveCampaignItem}
          {...modalProps}
          fieldsConfiguration={{
            TYPE:
              (modalProps?.chooseType && !activeCampaignItem) ||
              !!activeCampaignItem?.type,
            NAME: true,
            DESCRIPTION: true,
            EMAIL: false,
            ROLE: false,
            CAMPAIGN: false,
          }}
        />
        {items?.length ? (
          <div className="esg-campaign-entity__items">
            {items.map((item) => {
              const { name, type, id, description } = item;

              const itemType = type === 'topic' ? 'folder' : 'data-collection';

              return (
                <SectionCard
                  view="list"
                  prefixElement={<Icon name="clipboard-list" />}
                  key={id}
                  withMenu={hasPermission(['delete', 'update'])}
                  title={name}
                  description={description}
                  {...(type && {
                    status: {
                      children: mappedSectionTypeToTitle[itemType],
                      status: mappedSectionTypeToStatus[itemType],
                      icon: mappedSectionTypeToIcon[itemType],
                    },
                  })}
                  onDelete={
                    hasPermission(['delete']) ? onDelete(id, name) : undefined
                  }
                  onSelect={() => {
                    navigateTo &&
                      navigate(
                        `${navigateTo(id)}${type === 'data_collection_element' ? '?form=yes' : ''}`
                      );
                  }}
                  onEdit={
                    hasPermission(['update'])
                      ? () => openEditModal(item as CampaignItem)
                      : undefined
                  }
                  onAddDescriptionCallback={() =>
                    openEditModal(item as CampaignItem)
                  }
                  hasActions={hasPermission(['update'])}
                />
              );
            })}
          </div>
        ) : (
          <p className="esg-campaign-entity__empty">
            This part of campaign is empty.{' '}
            {!hasPermission(['update']) &&
              'Contact the admin for creating new elements'}
          </p>
        )}
      </StyledCampaignEntity>
    </BlockUI>
  );
};

export default CampaignEntity;

import { theme } from '@faxi/web-component-library';
import { flex, size } from '@faxi/web-css-utilities';
import styled from 'styled-components';

const StyledPostComment = styled.div`
  ${flex('row')};
  margin-top: auto;

  gap: ${theme.sizes.SIZE_12};

  .esg-post-comment__send {
    ${size(theme.sizes.SIZE_56)};
    background-color: var(--PRIMARY_1_1);
    border-radius: 50%;
    border: unset;

    svg {
      ${size(theme.sizes.SIZE_20)};
    }

    &::after {
      content: unset;
    }

    &:hover {
      background-color: var(--PRIMARY_1_1);
    }
  }

  .esg-post-comment {
  }
`;

export { StyledPostComment };

import { INameExtended } from '../components/Icon';
import { BaseModelExtended } from './BaseModel';
import { CampaignItem } from './Campaign';
import { Company, CompanyChild } from './Company';
import { Progress } from './Progress';

export enum FormStatus {
  InProgress = 'In Progress',
  InReview = 'In Review',
  Completed = 'Completed',
}

export type SessionAPI = {
  name: 'string';
  companyId: 'string';
  campaignId: 'string';
  selectedElementsIds: ['string'];
};

export type SessionSettings = {
  organisationId: string;
  organisationPath: string;
  isIncluded: boolean;
  dataCollectionElements: {
    id: string;
    path: string;
    isIncluded: boolean;
    exclusionJustification?: string;
  }[];
};

export type Session = BaseModelExtended & {
  campaign: CampaignItem;
  id: string;
  status: CampaignSessionStatus;
  campaignId: string;
  campaignName: string;
  settings: SessionSettings[];
  startDate: string;
  endDate: string;
  createdBy: string;
  updatedBy?: string;
  rootOrganisationId: string;
  progress: Progress;
  approval?: Progress;
};

export enum CampaignSessionStatus {
  Active = 'active',
  Finished = 'finished',
  Draft = 'draft',
}

export type CampaignSessionTreeNode = {
  id: string;
  organisationSessionId: string;
  name: string;
  companySessionId?: string;
  status: CampaignSessionStatus;
  description: string;
  path: {
    ids: string[];
    names: string[];
  };
  organisation: Company;
  campaign?: CampaignItem;
  parent?: {
    id: string;
    name: string;
  };
  iconName?: INameExtended;
  children?: CampaignSessionTreeNode[];
};

export type SessionsPageParams = { sessionId: string };

export type SessionTree = BaseModelExtended & {
  campaign: CampaignItem;
  children: SessionTree[];
  company: CompanyChild;
  status: CampaignSessionStatus;
  organisationSessionId: string;
};

export type RunSessionData = {
  companyIds: Array<string>;
};

export type SessionForm = {
  rootOrganisationId: string;
  organisationId: string;
  campaignId: string;
  sessionId: string;
  organisationSessionId: string;
  dataCollectionElementId: string;
  dataCollectionElementPath: string;
  status: `${FormStatus}`;
  createdBy: string;
  updatedBy?: string;
  createdAt: string;
  updatedAt: string;
};

import { SessionsPageParams } from 'models';
import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { BlockUI } from '../../../../helpers';
import { SelectedSessionProvider } from '../../context/SelectedSession';
import SessionSidebar from '../SessionSidebar';
import { StyledSessionDetails } from './SessionDetails.styled';

const SessionDetails: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();

  return (
    <BlockUI error={!sessionId}>
      <StyledSessionDetails className="esg-session-details">
        <SelectedSessionProvider sessionId={sessionId!}>
          <SessionSidebar />
          <div className="esg-session-details__content">
            <Outlet />
          </div>
        </SelectedSessionProvider>
      </StyledSessionDetails>
    </BlockUI>
  );
};

export default SessionDetails;

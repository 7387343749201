import { SelectOption } from '@faxi/web-component-library';
import { Session } from 'models';
import { createContext } from 'react';
import { KeyedMutator } from 'swr';

export type SessionContextProps = {
  sessions: Session[];
  search: string;
  selectedOrganisation?: SelectOption;
  isLoading: boolean;
  error: boolean;
  setSearch: (value: string) => void;
  setSelectedOrganisation: (selected: SelectOption) => void;
  mutateSessions: KeyedMutator<{
    data: Session[];
  }>;
};

export default createContext<SessionContextProps>({
  sessions: [],
  search: '',
  selectedOrganisation: { label: '', value: '' },
  isLoading: false,
  error: false,
  setSearch: () => {},
  setSelectedOrganisation: () => {},
  mutateSessions: () => new Promise(() => {}),
});

import env from '../../env';

const apiVersion = env.VITE_API_VERSION;

export const COMMENTS = {
  SESSION_FORM_ELEMENT_COMMENTS: (
    sessionId: string,
    formId: string,
    elementId: string
  ) =>
    `/campaign-sessions/${apiVersion}/organisation-sessions/${sessionId}/forms/${formId}/elements/${elementId}/comments`,
  SESSION_FORM_ELEMENT_COMMENTS_READ: (
    sessionId: string,
    formId: string,
    elementId: string
  ) =>
    `/campaign-sessions/${apiVersion}/organisation-sessions/${sessionId}/forms/${formId}/elements/${elementId}/comments/read`,
};

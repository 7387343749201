import { SessionsPageParams } from 'models';
import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

const SessionPage: FC = () => {
  const { sessionId } = useParams<SessionsPageParams>();

  if (!sessionId) return <></>;

  return <Outlet />;
};

export default SessionPage;

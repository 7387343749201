import { DataModuleEnum, ModuleConfig, ModuleConfigType } from 'models';

/**
 * Checks if a module's ID is not of the specified type.
 */
export const moduleIdIsNotOfType =
  (type?: DataModuleEnum) =>
  (module: ModuleConfig<ModuleConfigType, DataModuleEnum>) =>
    module.id !== type;

/**
 * Filters out modules and their elements of a specific type.
 */
export const filterModulesAndElementsByType =
  (type?: DataModuleEnum) =>
  (
    old: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
  ): ModuleConfig<ModuleConfigType, DataModuleEnum>[] =>
    old.filter(moduleIdIsNotOfType(type)).map(({ elements, ...rest }) => ({
      ...rest,
      elements: elements
        ? filterModulesAndElementsByType(type)(elements)
        : elements,
    }));

/**
 * Get all module IDs including IDs from nested elements.
 */
export const getAllModuleIds = (
  modules: ModuleConfig<ModuleConfigType, DataModuleEnum>[]
): string[] =>
  modules.reduce<string[]>((acc, curr) => {
    acc.push(curr.id);
    if (curr.elements) acc = acc.concat(getAllModuleIds(curr.elements));

    return acc;
  }, []);

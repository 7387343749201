import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(isYesterday);
dayjs.extend(isToday);

export const formatRelativeOrFullDate = (date: string | Date): string => {
  const givenDate = dayjs(date);

  if (givenDate.isToday()) {
    return givenDate.format(`[Today], HH:mm`);
  } else if (givenDate.isYesterday()) {
    return givenDate.format(`[Yesterday], HH:mm`);
  } else if (givenDate.isAfter(dayjs().subtract(7, 'days'), 'day')) {
    return givenDate.format(`dddd, HH:mm`);
  } else {
    return givenDate.format(`MMMM D, YYYY, HH:mm`);
  }
};
